import { getLocalStorage } from "../login";
import { apiRoutes } from "../../constants/apiconst";
import { axiosDevClient } from "..";

export const exportReward = async (auth: string) => {
  try {
    const response = await axiosDevClient.get(
      `${apiRoutes.rewards.exportReward}`,
      {
        headers: {
          "access-token": auth,
        },
        withCredentials: true,
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};
