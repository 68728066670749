import { Navigate } from "react-router";
import { routes } from "../packages/utils/routes";
import { useCheckToken } from "../modules/auth";

const ProtectedRoute = (props: { children: JSX.Element | JSX.Element[] }) => {
  const user: any = useCheckToken();

  if (!user?.user_name) {
    return <Navigate to={routes.login} />;
  } else {
    return <>{props.children}</>;
  }
};

export default ProtectedRoute;
