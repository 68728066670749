import React from "react";
import { activityData } from "../packages/utils/status";

const Activity = () => {
  return (
    <div className="text-white border border-borderColor px-5 ">
      <header className="flex items-center justify-between  py-2 ">
        <h2 className="font-normal text-xl">Activity</h2>
        <div className="bp4-html-select  bg-cardbg">
          <select className="" style={{backgroundColor:"#5F6B7C",color:"#EDEFF2"}}>
            <option >Recent</option>
            <option value="week">Last week</option>
            <option value="month">Last month</option>
            <option value="year">Last year</option>
          </select>
          <span className="bp4-icon bp4-icon-caret-down " style={{color:"#EDEFF2"}}></span>
        </div>
      </header>
      {/* body  */}
     {activityData.map((item,index)=>{
        return  <div key={index} className="flex items-center py-2 space-x-4 text-sm">
        <div>
            <img src={item.img} alt={item.img} className="h-8 w-10 xl:h-8 xl:w-8" />
        </div>
         <p>{item.content}</p>
      </div>
     })}
    </div>
  );
};

export default Activity;
