import React from 'react'
import { Oval } from 'react-loader-spinner'

const Spinner = () => {
  return (
    <div className="flex items-center justify-center   h-[88vh]">
    <Oval
      height={80}
      width={80}
      color="#ffffff"
      wrapperStyle={{}}
      wrapperClass=""
      visible={true}
      ariaLabel="oval-loading"
      secondaryColor="#ffffff"
      strokeWidth={2}
      strokeWidthSecondary={2}
    />
  </div>
  )
}

export default Spinner