import React from "react";
import { useNavigate } from "react-router";
import SharedLayout from "../components/SharedLayout";
import SupportHeader from "../components/SupportListHeader";
import { routes } from "../packages/utils/routes";

// open ticket page
const supportData = [
  {
    subject: "Lorem Ipsum is simply dummy text.",
    ticketId: "256856",
    regarding: "Varada related",
    date: "25-7-2022 20:00",
    lastUpdated: "25-7-2022 22:20",
    updatedBy: "Vaibhav P.",
  },
  {
    subject: "Lorem Ipsum is simply dummy text.",
    ticketId: "256856",
    regarding: "Varada related",
    date: "25-7-2022 20:00",
    lastUpdated: "25-7-2022 22:20",
    updatedBy: "Vaibhav P.",
  },
  {
    subject: "Lorem Ipsum is simply dummy text.",
    ticketId: "256856",
    regarding: "Varada related",
    date: "25-7-2022 20:00",
    lastUpdated: "25-7-2022 22:20",
    updatedBy: "Vaibhav P.",
  },
];

const Support = () => {
  const navigate = useNavigate()
  return (
      <section className=" px-8 py-8 h-[88vh] overflow-y-scroll scrollbar-thin scrollbar-thumb-cardbg">
        <div className=" flex justify-end mb-3">
          <div onClick={()=>navigate(routes.newticket)} className=" text-white bg-cardbg py-2 px-4 cursor-pointer hover:bg-bgBlack transition-all duration-200">
            Raise new ticket
          </div>
        </div>
        {/* header  */}
        <SupportHeader />

        {/* ***************body*************** */}
        {supportData.map((item,index)=>{
            const {subject,ticketId,regarding,date,lastUpdated,updatedBy} = item
            return <div key={index} className="grid grid-cols-[24%_11%_16%_16%_16%_16%] xl:grid-cols-[26%_11%_16%_15%_15%_15%]  py-3   gap-1 text-base bg-[#111418] text-[#C5CBD3]">
            <div className="px-3 text-[#2F80ED] hover:underline cursor-pointer">
              <h2>{subject}</h2>
            </div>
            <div className=" ">
              <h2>{ticketId}</h2>
            </div>
            <div className="">
              <h2>{regarding}</h2>
            </div>
            <div className="">
              <h2>{date}</h2>
            </div>
            <div className="">
              <h2>{lastUpdated}</h2>
            </div>
            <div className="">
              <h2>{updatedBy}</h2>
            </div>
          </div>
        })}
        
      </section>
  );
};

export default Support;
