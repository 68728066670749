import { Dialog } from "@blueprintjs/core";
import React, { useState } from "react";
import LogFilter from "../components/LogFilter";
import SharedLayout from "../components/SharedLayout";
import SingleLog from "../components/SingleLog";
const logData = [
  {
    id: "1",
    color: "bg-[#FFFFFF]",
    textcolor: "text-[#FFFFFF]",
    time: "2m",
    app: "app [web.1]",
    tasktitle: "Image Exists (0.6ms)",
    taskcontent:
      "SELECT “tags”, * FROM “tags” INNER JOIN “taggings” ON “tags” id “tags”, * FROM “tags” INNER JOIN “taggings” ON “tags” shubham",
  },
  {
    id: "2",
    color: "bg-[#2F80ED]",
    textcolor: "text-[#2F80ED]",
    time: "2m",
    app: "app [web.1]",
    tasktitle: "Image Exists (0.6ms)",
    taskcontent:
      "SELECT “tags”, * FROM “tags” INNER JOIN “taggings” ON “tags” id “tags”, * FROM “tags” INNER JOIN “taggings” ON “tags” rawat",
  },
  {
    id: "3",
    color: "bg-[#27AE60]",
    textcolor: "text-[#27AE60]",
    time: "2m",
    app: "app [web.1]",
    tasktitle: "Image Load (0.7ms)",
    taskcontent:
      "SELECT “tags”, * FROM “tags” INNER JOIN “taggings” ON “tags” id “tags”, * FROM “tags” INNER JOIN “taggings” ON “tags” pikaa",
  },
  {
    id: "4",
    color: "bg-[#EB5757]",
    textcolor: "text-[#EB5757]",
    time: "2m",
    app: "app [web.1]",
    tasktitle: "Image Exists (0.6ms)",
    taskcontent:
      "SELECT “tags”, * FROM “tags” INNER JOIN “taggings” ON “tags” id “tags”, * FROM “tags” INNER JOIN “taggings” ON “tags”",
  },
  {
    id: "5",
    color: "bg-[#27AE60]",
    textcolor: "text-[#27AE60]",
    time: "2m",
    app: "app [web.1]",
    tasktitle: "Image Load (0.7ms)",
    taskcontent:
      "SELECT “tags”, * FROM “tags” INNER JOIN “taggings” ON “tags” id “tags”, * FROM “tags”  ON “tags”",
  },
  {
    id: "6",
    color: "bg-[#EB5757]",
    textcolor: "text-[#EB5757]",
    time: "2m",
    app: "app [web.1]",
    tasktitle: "Image Exists (0.6ms)",
    taskcontent:
      "SELECT “tags”, * FROM “tags” INNER JOIN “taggings” ON “tags”, * FROM “tags” INNER JOIN “taggings” ON “tags”",
  },
  {
    id: "7",
    color: "bg-[#27AE60]",
    textcolor: "text-[#27AE60]",
    time: "2m",
    app: "app [web.1]",
    tasktitle: "Image Load (0.7ms)",
    taskcontent:
      "SELECT “tags”, * FROM “tags” INNER JOIN “taggings” ON “tags” id “tags”, * FROM “tags” INNER JOIN “taggings” ON “tags”",
  },
  {
    id: "8",
    color: "bg-[#FBB360]",
    textcolor: "text-[#FBB360]",
    time: "2m",
    app: "app [web.1]",
    tasktitle: "Image Exists (0.6ms)",
    taskcontent:
      "SELECT “tags”, * FROM “tags” INNER JOIN “taggings”  id “tags”, * FROM “tags” INNER JOIN “taggings” ON “tags 2” 8",
  },
  {
    id: "9",
    color: "bg-[#EB5757]",
    textcolor: "text-[#EB5757]",
    time: "2m",
    app: "app [web.1]",
    tasktitle: "Image Exists (0.6ms)",
    taskcontent:
      "SELECT “tags”, * FROM “tags” INNER JOIN ON “tags”  “tags”  “taggings” ON “tags 9”",
  },
];

const Log = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalLogData, setModalLogData] = useState("");
  return (
    <>
      <section className="grid grid-cols-4 py-4 px-1 overflow-y-scroll h-[88vh]  scrollbar-thumb-cardbg scrollbar-track-[#1E1E1E] scrollbar-thin ">
        <div className="col-span-3 ">
          <SingleLog
            logData={logData}
            setIsOpen={setIsOpen}
            setModalLogData={setModalLogData}
          />
        </div>
        <LogFilter />
      </section>
      {/* modal  */}
      <div>
        <Dialog
          className="bg-cardbg"
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
        >
          <div className="text-center p-5 space-y-5">
            <h2 className="text-white text-base">{modalLogData} </h2>
            <button
              onClick={() => setIsOpen(false)}
              className="py-2 px-5 text-white bg-bgBlack "
            >
              close
            </button>
          </div>
        </Dialog>
      </div>
    </>
  );
};

export default Log;

//
