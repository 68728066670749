import { IRoutes } from "../interfaces/routes/routes"

export const routes:IRoutes = {
    home:"/",
    createclient:"/client",
    clientlist:"/clientlist",
    healthcheck:"/healthcheck",
    log:"/log",
    // allrewards:"/rewards",
    clientwise:"/rewards",
    uploadrewards:"/uploadrewards",
    updaterewardStatus:"/updaterewardstatus",
    order:"/orders",
    updateorderStatus:"/updateorderstatus",
    systemmonitorUsage:"/systemmonitor/usage",
    systemmonitorLog:"/systemmonitor/log",
    systemmonitorContainer:"/systemmonitor/container",
    singleContainer:"/systemmonitor/container/:id",
    support:"/support",
    newticket:"/newticket",
    profile:"/profile",
    login:"/login"

}