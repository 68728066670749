export const logData = [
    {
      id: "1",
      color: "bg-[#FFFFFF]",
      textcolor: "text-[#FFFFFF]",
      time: "2m",
      app: "app [web.1]",
      tasktitle: "Image Exists (0.6ms)",
      taskcontent:
        "SELECT “tags”, * FROM “tags” INNER JOIN “taggings” ON “tags” id “tags”, * FROM “tags” INNER JOIN “taggings” ON “tags” shubham",
    },
    {
      id: "2",
      color: "bg-[#2F80ED]",
      textcolor: "text-[#2F80ED]",
      time: "2m",
      app: "app [web.1]",
      tasktitle: "Image Exists (0.6ms)",
      taskcontent:
        "SELECT “tags”, * FROM “tags” INNER JOIN “taggings” ON “tags” id “tags”, * FROM “tags” INNER JOIN “taggings” ON “tags” rawat",
    },
    {
      id: "3",
      color: "bg-[#27AE60]",
      textcolor: "text-[#27AE60]",
      time: "2m",
      app: "app [web.1]",
      tasktitle: "Image Load (0.7ms)",
      taskcontent:
        "SELECT “tags”, * FROM “tags” INNER JOIN “taggings” ON “tags” id “tags”, * FROM “tags” INNER JOIN “taggings” ON “tags” pikaa",
    },
    {
      id: "4",
      color: "bg-[#EB5757]",
      textcolor: "text-[#EB5757]",
      time: "2m",
      app: "app [web.1]",
      tasktitle: "Image Exists (0.6ms)",
      taskcontent:
        "SELECT “tags”, * FROM “tags” INNER JOIN “taggings” ON “tags” id “tags”, * FROM “tags” INNER JOIN “taggings” ON “tags”",
    },
    {
      id: "5",
      color: "bg-[#27AE60]",
      textcolor: "text-[#27AE60]",
      time: "2m",
      app: "app [web.1]",
      tasktitle: "Image Load (0.7ms)",
      taskcontent:
        "SELECT “tags”, * FROM “tags” INNER JOIN “taggings” ON “tags” id “tags”, * FROM “tags”  ON “tags”",
    },
    {
      id: "6",
      color: "bg-[#EB5757]",
      textcolor: "text-[#EB5757]",
      time: "2m",
      app: "app [web.1]",
      tasktitle: "Image Exists (0.6ms)",
      taskcontent:
        "SELECT “tags”, * FROM “tags” INNER JOIN “taggings” ON “tags”, * FROM “tags” INNER JOIN “taggings” ON “tags”",
    },
    {
      id: "7",
      color: "bg-[#27AE60]",
      textcolor: "text-[#27AE60]",
      time: "2m",
      app: "app [web.1]",
      tasktitle: "Image Load (0.7ms)",
      taskcontent:
        "SELECT “tags”, * FROM “tags” INNER JOIN “taggings” ON “tags” id “tags”, * FROM “tags” INNER JOIN “taggings” ON “tags”",
    },
    {
      id: "8",
      color: "bg-[#FBB360]",
      textcolor: "text-[#FBB360]",
      time: "2m",
      app: "app [web.1]",
      tasktitle: "Image Exists (0.6ms)",
      taskcontent:
        "SELECT “tags”, * FROM “tags” INNER JOIN “taggings”  id “tags”, * FROM “tags” INNER JOIN “taggings” ON “tags 2” 8",
    },
    {
      id: "9",
      color: "bg-[#EB5757]",
      textcolor: "text-[#EB5757]",
      time: "2m",
      app: "app [web.1]",
      tasktitle: "Image Exists (0.6ms)",
      taskcontent:
        "SELECT “tags”, * FROM “tags” INNER JOIN ON “tags”  “tags”  “taggings” ON “tags 9”",
    },
  ];