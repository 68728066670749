import React from 'react'

const EarningSummary = () => {
  return (
    <section className='border border-borderColor text-white bg-cardbg'>
     <h2 className='font-normal text-xl p-3'>Earning Summary</h2>
     <div className=' flex space-x-3 items-center p-3 border-b border-borderColor'>
      <h3 className='text-[#EDEFF2] text-xl'>5260</h3> 
      <span className='text-[#8F99A8] text-base font-normal'>Total order</span>
     </div>
     <div className=' flex space-x-3 items-center p-3 border-b border-borderColor'>
      <h3 className='text-[#238551] text-xl'>₹42.62</h3> 
      <span className='text-[#8F99A8] text-base font-normal'>Available for tranfer</span>
     </div>
     <div className=' flex space-x-3 items-center p-3 border-b border-borderColor'>
      <h3 className='text-[#EDEFF2] text-xl'>₹0.00</h3> 
      <span className='text-[#8F99A8] text-base font-normal'>Pending</span>
     </div>
     <div className=' flex space-x-3 items-center p-3 border-b border-borderColor'>
     <h3 className='text-[#EDEFF2] text-xl'>₹0.00</h3> 
      <span className='text-[#8F99A8] text-base font-normal'>Project pending</span>
     </div>
    </section>
  )
}

export default EarningSummary