import { routes } from "./routes";

// side bar menu data
export const menuData = [
  {
    menu: "Home",
    url: routes.home,
  },
  {
    menu: "Client",
    submenu: [
      // { title: "Create Client", url: routes.createclient },
      // { title: "Client List", url: routes.clientlist },
      { title: "Health Check", url: routes.healthcheck },
      { title: "Log", url: routes.log },
    ],
  },
  {
    menu: "Reward",
    submenu: [
      // {
      //   title: "All Rewards",
      //   url: routes.allrewards,
      // },
      {
        title: "Rewards",
        url: routes.clientwise,
      },
      {
        title: "Upload Rewards",
        url: routes.uploadrewards,
      },
      {
        title: "Update Reward Status",
        url: routes.updaterewardStatus,
      },
    ],
  },
  {
    menu: "Order",
    url: routes.order,
    submenu:[
      {
        title:"Update Order Status",
        url:routes.updateorderStatus
      }
    ]
  },
  {
    menu: "System Monitor",
    submenu:[
      {
        title:"Usage",
        url:routes.systemmonitorUsage
      },
      {
        title:"Log",
        url:routes.systemmonitorLog
      },
      {
        title:"Container",
        url:routes.systemmonitorContainer
      },
    ]
  },
  {
    menu: "Support",
    url: routes.support,
  },
];

// client card data
export const clientData = [
  {
    name: "Nikunj Patel",
    clientID: "ABC56X8I",
    orders: "5000",
    amount: "₹489566",
    successRate: "98%",
    img: "userimage.png",
  },
  {
    name: "Nikunj Patel",
    clientID: "ABC56X8I",
    orders: "5000",
    amount: "₹489566",
    successRate: "98%",
    img: "userimage.png",
  },
  {
    name: "Nikunj Patel",
    clientID: "ABC56X8I",
    orders: "5000",
    amount: "₹489566",
    successRate: "98%",
    img: "userimage.png",
  },
  {
    name: "Nikunj Patel",
    clientID: "ABC56X8I",
    orders: "5000",
    amount: "₹489566",
    successRate: "98%",
    img: "userimage.png",
  },
  {
    name: "Nikunj Patel",
    clientID: "ABC56X8I",
    orders: "5000",
    amount: "₹489566",
    successRate: "98%",
    img: "userimage.png",
  },
  {
    name: "Nikunj Patel",
    clientID: "ABC56X8I",
    orders: "5000",
    amount: "₹489566",
    successRate: "98%",
    img: "userimage.png",
  },
];
