import { Dialog } from "@blueprintjs/core";
import React, { useState } from "react";
import SharedLayout from "../components/SharedLayout";

const logData = [
  {
    id: "1",
    color: "bg-[#FFFFFF]",
    textcolor: "text-[#FFFFFF]",
    time: "2m",
    app: "app [web.1]",
    tasktitle: "Image Exists (0.6ms)",
    taskcontent:
      "SELECT “tags”, * FROM “tags” INNER JOIN “taggings” ON “tags” id “tags”, * FROM “tags” INNER JOIN “taggings” ON “tags” shubham",
  },
  {
    id: "2",
    color: "bg-[#2F80ED]",
    textcolor: "text-[#2F80ED]",
    time: "2m",
    app: "app [web.1]",
    tasktitle: "Image Exists (0.6ms)",
    taskcontent:
      "SELECT “tags”, * FROM “tags” INNER JOIN “taggings” ON “tags” id “tags”, * FROM “tags” INNER JOIN “taggings” ON “tags” rawat",
  },
  {
    id: "3",
    color: "bg-[#27AE60]",
    textcolor: "text-[#27AE60]",
    time: "2m",
    app: "app [web.1]",
    tasktitle: "Image Load (0.7ms)",
    taskcontent:
      "SELECT “tags”, * FROM “tags” INNER JOIN “taggings” ON “tags” id “tags”, * FROM “tags” INNER JOIN “taggings” ON “tags” pikaa",
  },
  {
    id: "4",
    color: "bg-[#EB5757]",
    textcolor: "text-[#EB5757]",
    time: "2m",
    app: "app [web.1]",
    tasktitle: "Image Exists (0.6ms)",
    taskcontent:
      "SELECT “tags”, * FROM “tags” INNER JOIN “taggings” ON “tags” id “tags”, * FROM “tags” INNER JOIN “taggings” ON “tags”",
  },
  {
    id: "5",
    color: "bg-[#27AE60]",
    textcolor: "text-[#27AE60]",
    time: "2m",
    app: "app [web.1]",
    tasktitle: "Image Load (0.7ms)",
    taskcontent:
      "SELECT “tags”, * FROM “tags” INNER JOIN “taggings” ON “tags” id “tags”, * FROM “tags”  ON “tags”",
  },
  {
    id: "6",
    color: "bg-[#EB5757]",
    textcolor: "text-[#EB5757]",
    time: "2m",
    app: "app [web.1]",
    tasktitle: "Image Exists (0.6ms)",
    taskcontent:
      "SELECT “tags”, * FROM “tags” INNER JOIN “taggings” ON “tags”, * FROM “tags” INNER JOIN “taggings” ON “tags”",
  },
  {
    id: "7",
    color: "bg-[#27AE60]",
    textcolor: "text-[#27AE60]",
    time: "2m",
    app: "app [web.1]",
    tasktitle: "Image Load (0.7ms)",
    taskcontent:
      "SELECT “tags”, * FROM “tags” INNER JOIN “taggings” ON “tags” id “tags”, * FROM “tags” INNER JOIN “taggings” ON “tags”",
  },
  {
    id: "8",
    color: "bg-[#FBB360]",
    textcolor: "text-[#FBB360]",
    time: "2m",
    app: "app [web.1]",
    tasktitle: "Image Exists (0.6ms)",
    taskcontent:
      "SELECT “tags”, * FROM “tags” INNER JOIN “taggings”  id “tags”, * FROM “tags” INNER JOIN “taggings” ON “tags 2” 8",
  },
  {
    id: "9",
    color: "bg-[#EB5757]",
    textcolor: "text-[#EB5757]",
    time: "2m",
    app: "app [web.1]",
    tasktitle: "Image Exists (0.6ms)",
    taskcontent:
      "SELECT “tags”, * FROM “tags” INNER JOIN ON “tags”  “tags”  “taggings” ON “tags 9”",
  },
];

const SystemMonitorLog = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalLogData, setModalLogData] = useState("");
  return (
    <>
      <section className="px-8 py-8 h-[88vh] overflow-y-scroll scrollbar-thin scrollbar-thumb-cardbg">
        <div className="col-span-3 ">
          {logData.map((item, index) => {
            const { color, app, time, tasktitle, taskcontent, textcolor } =
              item;
            return (
              <div
                key={index}
                className="w-full  h-9 text-white grid grid-cols-[5%_5%_15%_20%_calc(55%-2rem)] xl:grid-cols-[5%_5%_10%_20%_calc(60%-2rem)] gap-2 px-4 xl:px-2 text-sm "
              >
                <div className=" flex flex-col items-center  ">
                  <div className="rounded-full  h-5 w-5 flex items-center justify-center border">
                    <div className={`${color} rounded-full h-3 w-3`}></div>
                  </div>
                  {index === logData.length - 1 ? null : (
                    <div className="h-[calc(100%-20px)] border border-[#F6F7F9]"></div>
                  )}
                </div>
                <div className=" flex text-iconColor justify-center ">
                  <h3>{time}</h3>
                </div>
                <div className=" flex ">
                  <h2>{app}</h2>
                </div>
                <div className="  ">
                  <h2 className={`${textcolor}`}>{tasktitle}</h2>
                </div>
                <div className="text-iconColor">
                  <h2
                    onClick={() => {
                      setIsOpen(true);
                      setModalLogData(taskcontent);
                    }}
                    className="truncate  hover:text-[#2F80ED] hover:underline cursor-pointer"
                  >
                    {taskcontent}
                  </h2>
                </div>
              </div>
            );
          })}
        </div>
      </section>
      {/* modal  */}
      <div>
        <Dialog
          className="bg-cardbg"
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
        >
          <div className="text-center p-5 space-y-5">
            <h2 className="text-white text-base">{modalLogData} </h2>
            <button
              onClick={() => setIsOpen(false)}
              className="py-2 px-5 text-white bg-bgBlack "
            >
              close
            </button>
          </div>
        </Dialog>
      </div>
    </>
  );
};

export default SystemMonitorLog;
