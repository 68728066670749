import React, { useState } from "react";
import FormInput from "../components/profile/FormInput";
import ProfileInfoForm from "../components/profile/ProfileInfoForm";
import SharedLayout from "../components/SharedLayout";
import { useAuthContext } from "../context/authContext";

const UserProfile = () => {
const [isShowPasswordForm,setIsShowPasswordForm] = useState(false)
const {userData} = useAuthContext()
  return (
      <section className="text-white px-8 h-[88vh]  overflow-y-scroll  py-5 scrollbar-thin ">
        <div className=" flex space-x-3 items-center">
          <div>
            <img src="userimage.png" alt="user" className="h-11" />
          </div>
          <div>
            <h2 className="text-xl capitalize">{userData?.user_name || "User name"}</h2>
            <p className="text-xs hover:text-[#2F80ED] cursor-pointer">
              Edit photo
            </p>
          </div>
        </div>
        {/* ************** ///////\\\\\\\\\*********** */}
        <div className=" grid grid-cols-4 lg:gap-8 xl:gap-10  mt-10">
          <div className=" space-y-4">
            <div onClick={()=>setIsShowPasswordForm(false)} className={`${!isShowPasswordForm ? "bg-cardbg" :"bg-bgBlack"}  py-2 px-3 text-iconColor cursor-pointer `}>
              Personal Information
            </div>
            <div onClick={()=>setIsShowPasswordForm(true)} className={`${isShowPasswordForm ? "bg-cardbg" :"bg-bgBlack"}  py-2 px-3 text-iconColor cursor-pointer`}>
              Forgot Password
            </div>
          </div>
          <div className="col-span-3  h-[65vh] w-[52vw] ">
            <div className="h-full bg-bgBlack py-5 px-10 ">
             
              {!isShowPasswordForm ?  <ProfileInfoForm password={false} {...userData}/>  : <ProfileInfoForm password/> }
             

            </div>
          </div>
        </div>
      </section>
  );
};

export default UserProfile;
