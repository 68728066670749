import React, { useState } from "react";
import { FaUserCircle, FaBell } from "react-icons/fa";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import { menuData } from "../packages/utils/menu";
import { MdOutlineLogout } from "react-icons/md";
import Menu from "./Menu";
import { useNavigate } from "react-router";
import { routes } from "../packages/utils/routes";
import { Link } from "react-router-dom";
import { useLogout } from "../packages/api/logout";

const Sidebar = () => {
  const navigate = useNavigate()
  const logout = useLogout()

  return (
    <div className=" h-[100vh] bg-bgSidebar  ">
      {/* header */}
      <div className=" flex justify-between items-center py-4 px-4">
        <div>
          <Link to={routes.home}>
          <img src="/logo.png" alt="logo" className="h-8 cursor-pointer" />
          </Link>
        </div>
        <div className="text-iconColor flex text-xl space-x-4">
          <FaUserCircle className="cursor-pointer hover:scale-110" onClick={()=>navigate(routes.profile)} />
        </div>
      </div>

      {/* menu  */}
      <div className=" h-[81%]  overflow-y-scroll scrollbar-none">
    {menuData.map((item,index:number)=>{
        const {menu,url,submenu} = item
        return <Menu key={index} menu={menu} submenu={submenu} url={url}/>
    })}
      </div>
      
      {/* footer  */}
      <div className="  px-4 py-2 ">
        <button onClick={()=>logout()} className="text-white border border-borderColor bg-cardbg flex items-center justify-center py-2  space-x-2 w-full">
          <MdOutlineLogout /> <p>Logout</p>
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
