
import HealthChart from "../components/healthcheck/HealthChart";
import SharedLayout from "../components/SharedLayout";


const data = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
];

const healthChartData = [
  {
    data:data,
    title:"Real-time collaboration server ",
    subtitle:"0.00056 hours/2 hour cpu usage",
    color:"#FBB360"
  },
  {
    data:data,
    title:"APIs & Web Application ",
    subtitle:"0.00056 hours/2 hour cpu usage",
    color:"#2F80ED"
  },
  {
    data:data,
    title:"AWS Infrastructure ",
    subtitle:"0.00056 hours/2 hour cpu usage",
    color:"#219653"
  }
]

const HealthCheck = () => {
  return (
      <section className="px-8 py-8 h-[88vh] overflow-y-scroll scrollbar-thin scrollbar-thumb-cardbg ">
        <div className="grid grid-cols-2 gap-8">
          {healthChartData.map((item,index)=>{
            return <HealthChart key={index} {...item}/>
          })}
          
          
        </div>
      </section>
  );
};

export default HealthCheck;
