import { Dialog } from '@blueprintjs/core'
import React, { useRef, useState } from 'react'
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { FaFileCsv } from 'react-icons/fa';
import Spinner from '../components/Spinner';
import { apiRoutes } from '../packages/constants/apiconst';
import { usePost } from '../packages/hooks/usePost';

const UpdateOrderStatus = () => {
    const csvPickerRef: { current: any } = useRef();
    const [values, setValues] = useState<any>({
        csv: null,
    });
    const {isLoading,error,data,post,isOpen,response,setisOpen,setResponse} = usePost()
    const formData = new FormData()
    const [formState, setFormState] = useState(formData)

    const handleChange = (e: any) => {
        const value = e.target.files[0];
        if (value.type === "text/csv") {

            setFormState((formState) => {
                formState.append("csv", value)
                return formState
            })

            setValues({ csv: value });
        }
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (values.csv !== null) {
            post(`${apiRoutes.orders.updateOrderStatus}`,formState)
            // mutate(formState)
        } else {
            setResponse({ message: "please upload csv file" })
            setisOpen(true)
        }

        setValues({ csv: null })
        setFormState(formData)
    };

    if (isLoading) {
        return <Spinner />
    }


    return (
        <section className="flex items-center justify-center h-[88vh] scrollbar-thin scrollbar-thumb-cardbg">
            <div className="text-center text-white  bg-[#111418] w-[50%] xl:w-[40%] py-10 space-y-5">
                <div className="space-y-2">
                    <h2 className="text-xl"> Update Order Status</h2>

                </div>

                <form onSubmit={handleSubmit} className="space-y-7">
                    <div className="space-y-3 ">
                        <div className='flex items-center justify-center space-x-5'>
                            <input
                                ref={csvPickerRef}
                                type="file"
                                name="csv"
                                accept={".csv"}
                                hidden
                                onChange={handleChange}

                            />
                            <div
                                onClick={() =>
                                    csvPickerRef.current && csvPickerRef.current.click()
                                }
                                className="bg-cardbg px-3 py-2 cursor-pointer flex items-center space-x-2"
                            >
                                <FaFileCsv />
                                <p>Choose csv file</p>
                            </div>
                        </div>
                        {
                            values.csv !== null && <div className=' flex items-center justify-center space-x-2'>
                                <AiOutlineCheckCircle className='text-lg' />
                                <span className='text-gray-400'>{values.csv.name}</span>
                            </div>
                        }
                    </div>



                    <div className="">
                        <button
                            type="submit"
                            className="border border-[#2D72D2] text-[#2D72D2] px-4 py-2"
                        >
                            Update Order Status
                        </button>
                    </div>
                </form>
            </div>
            <Dialog
                isOpen={isOpen}
                onClose={() => {
                    setisOpen(false)
                    setResponse(null)
                    setFormState(formData)
                }}
                className="bg-cardbg"
            >
                <div className="text-white text-center py-5">
                    {response && <h2 className="text-lg">{response?.data?.data?.message || response?.message}</h2>}

                    <button
                        onClick={() => {
                            setisOpen(false)
                            setResponse(null)
                            setFormState(formData)
                        }}
                        className="bg-bgBlack px-4 py-2 text-white mt-5 "
                    >
                        close
                    </button>
                </div>
            </Dialog>
        </section>
    )
}

export default UpdateOrderStatus