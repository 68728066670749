import React, { useRef, useState } from "react";
import SharedLayout from "./SharedLayout";
import { MdAttachFile } from "react-icons/md";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

// file upload rah gaya he 

const OpenNewTicket = () => {
  const [values, setValues] = useState({
    title: "",
    regarding: "",
    description: "",
    
  });

  const [attachment,setAttachment]= useState(null)
  const [isPreviewShow, setIsPreviewShow] = useState(false);
  const imageRefPicker:{current:any} = useRef()

  const handleCancel = ()=>{
    const newValues = {title:"",regarding:"",description:""}
    setValues(newValues)
    setAttachment(null)
    setIsPreviewShow(false)
  }

  const handleChange = (e: any) => {
    let name = e.target.name;
    let value = e.target.value;
    const newValues = { ...values, [name]: value };
    setValues(newValues);
  };

  const addPreviewImage = (e:any)=>{
   const reader = new FileReader()
   if (e.target.files[0]) {
    reader.readAsDataURL(e.target.files[0])
   }
   reader.onload = (readerEvent:any) =>{
    setAttachment(readerEvent.target.result)
   }
  }

  return (
      <section className="text-white grid grid-cols-2 px-5 xl:px-8 py-3 gap-5 xl:gap-20 h-[88vh]  overflow-y-scroll   scrollbar-thin">
        {/* left  */}
        <div className="">
          <h2 className="text-lg font-medium mb-1">Open a Support Ticket</h2>
          <h4 className="text-xs mb-4">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry unknown printer took a galley of type and scrambled
            <span className="text-[#2F80ED] hover:underline cursor-pointer mx-1">
              www.support.com
            </span>
          </h4>

          <form className="">
            <div className="bp4-form-group  mb-5">
              <label className="bp4-label" htmlFor="form-group-input">
                Title
                <span className="bp4-text-muted mx-2">(required)</span>
              </label>
              <div className="bp4-form-content">
                <div className="bp4-input-group w-[352px] xl:w-[400px]">
                  <input
                    id="form-group-input"
                    type="text"
                    className="bp4-input"
                    style={{
                      // width: "400px",
                      backgroundColor: "#111418",
                      border: "1px solid #8F99A8",
                      color: "#ffffff",
                    }}
                    placeholder="Enter your ticket title here"
                    dir="auto"
                    value={values.title}
                    onChange={handleChange}
                    name="title"
                  />
                </div>
              </div>
            </div>

            {/* ************ */}
            <div className="bp4-html-select  mb-5">
              <label
                className="bp4-label "
                style={{ marginBottom: "5px" }}
                htmlFor="form-group-select"
              >
                What is this regarding?
              </label>

              <div className="bp4-input-group w-[352px] xl:w-[400px]">
                <select
                  id="form-group-select"
                  className="w-auto xl:w-[400px]"
                  style={{
                    // width: "400px",
                    backgroundColor: "#111418",
                    border: "1px solid #8F99A8",
                    color: "#ffffff",
                  }}
                  value={values.regarding}
                  onChange={handleChange}
                  name="regarding"
                >
                  <option >choose an option</option>
                  <option value="Account Billing">Account Billing</option>
                  <option value="Account Billing">Account Billing</option>
                  <option value="Account Billing">Account Billing</option>
                </select>
                <span
                  className="bp4-icon bp4-icon-caret-down"
                  style={{ marginTop: "-2px" }}
                ></span>
              </div>
            </div>

            {/* ****************** */}
            <div className="mb-5">
              <label
                className="bp4-label"
                htmlFor="form-group-text"
                style={{ marginBottom: "5px" }}
              >
                Descriptions
                <span className="bp4-text-muted mx-2">(required)</span>
              </label>
              <textarea
                className="bp4-input .modifier w-[352px] xl:w-[400px]"
                id="form-group-text"
                style={{
                  // width: "400px",
                  height: "200px",
                  backgroundColor: "#111418",
                  border: "1px solid #8F99A8",
                  color: "#ffffff",
                }}
                dir="auto"
                value={values.description}
                onChange={handleChange}
                name="description"
              ></textarea>
            </div>

            {/* ***************** */}
            <div className="border border-[#2F80ED] w-[352px] xl:w-[400px] h-6 flex items-center justify-center cursor-pointer">
              <input ref={imageRefPicker} type="file" hidden name="attachment" onChange={addPreviewImage}   />
              <div onClick={()=> imageRefPicker && imageRefPicker?.current.click()} className="flex items-center space-x-2 text-[#2F80ED]">
                <MdAttachFile
                  className="text-sm text-[#2F80ED] rotate-45"
                  style={{ color: "#2F80ED" }}
                />
                <h4 className=" text-xs">Attachment file</h4>
              </div>
            </div>

            {/* buttons  */}
            <div className="mt-5 w-[352px] xl:w-[400px] flex justify-end text-white text-xs space-x-5">
              <button onClick={handleCancel} type={"reset"} className="px-4 py-2 bg-cardbg">
                Cancel
              </button>
              <button type={"submit"} className="bg-[#2F80ED] py-2 px-8">
                Open Ticket
              </button>
            </div>
          </form>
        </div>

        {/* right  */}
        <div className=" ">
          <div className="w-[380px] xl:w-[400px]  mt-16">
            <div className="flex items-center justify-between pb-2">
              <h2>Preview</h2>
              <div
                className=""
                onClick={() => setIsPreviewShow(!isPreviewShow)}
              >
                {isPreviewShow ? (
                  <AiOutlineEye className="text-[#2F80ED] text-lg" />
                  
                ) : (
                  <AiOutlineEyeInvisible className="text-[#2F80ED] text-lg" />
                )}
              </div>
            </div>

            <div className="w-[380px] xl:w-[400px]   min-h-[24rem] border border-borderColor bg-[#111418] space-y-3 px-3 py-2">
             {
              isPreviewShow ? <>
              <h2 className="text-lg text-[#2F80ED] capitalize">
               {values.title}
              </h2>
              <h4 className="text-sm">{values.regarding}</h4>
              <h6 className="capitalize text-sm"> {values.description}</h6>
             {attachment && <img src={attachment} alt="image" className=" h-52 w-full" /> } 
            </> :  <div className="flex items-center justify-center h-96 px-3">
                <h3>
                You hide the preview. if you view preview <span className="text-[#2F80ED]"> please turn on eye icon </span>
                </h3>
             </div>
             } 
            
            </div>
          </div>
        </div>
      </section>
  );
};

export default OpenNewTicket;
