// Home chart data 
export const homeChartData = [
    {name: 'DDOG', uv: 1080, pv: 2400, amt: 2400},
    {name: 'TTD', uv: 139, pv: 2400, amt: 2400},
    {name: 'ZI', uv: 111, pv: 2400, amt: 2400},
    {name: 'DOCS', uv: 80, pv: 2400, amt: 2400},
    {name: 'TYL', uv: 40, pv: 2400, amt: 2400},
]

//data for health charts 
export const data = [
    {
      name: "Page A",
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: "Page B",
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: "Page C",
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: "Page D",
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: "Page E",
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: "Page F",
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
  ]; 
