import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import ClientCard from "./ClientCard";
import { clientData } from "../packages/utils/menu";
import { Outlet } from "react-router";
const SharedLayout = () => {
  return (
    <main className="grid grid-cols-[20%_80%] xl:grid-cols-6 ">
      {/* sidebar */}
      <Sidebar />

      {/* main part  */}
      <div className="xl:col-span-5">
        <Navbar />
         <Outlet/>
      </div>
    </main>
  );
};

export default SharedLayout;
// props: { children: JSX.Element | JSX.Element[]}
