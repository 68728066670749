
interface IApiConst {
    STORE_BASE_URL: string,
    AUTH_BASE_URL: string
}

interface IApiRoutes {
    orders: {
        getAllOrders: string
        exportOrders: string
        updateOrderStatus: string
    },
    rewards: {
        bulkRewardUpload: string
        bulkRewardStatusUpdate: string
        exportReward: string
        getAllRewards: string
    },
    login: {
        loginStoreUser: string
        generateToken: string
        refreshToken:string
    }
}

export const apiConst: IApiConst = {
    STORE_BASE_URL: process.env.REACT_APP_STORE_BASE_URL || "",
    AUTH_BASE_URL: process.env.REACT_APP_AUTH_BASE_URL || "",
}

export const apiRoutes: IApiRoutes = {
    orders: {
        getAllOrders: apiConst.STORE_BASE_URL + "/client/orders",
        exportOrders: apiConst.STORE_BASE_URL + "/service/orders/export-orders",
        updateOrderStatus: apiConst.STORE_BASE_URL + "/service/orders/update-orders"
    },
    rewards: {
        bulkRewardUpload: apiConst.STORE_BASE_URL + "/service/bulk/upload-rewards",
        bulkRewardStatusUpdate: apiConst.STORE_BASE_URL + "/service/bulk/upload-rewards-status",
        exportReward: apiConst.STORE_BASE_URL + "/service/rewards/export",
        getAllRewards: apiConst.STORE_BASE_URL + "/client/store/reward"
    },
    login: {
        loginStoreUser: apiConst.AUTH_BASE_URL + "/store/user/login",
        generateToken: apiConst.AUTH_BASE_URL + "/store/user/generate-token",
        refreshToken:apiConst.AUTH_BASE_URL + "/store/user/get-access-token"

    }
} 