import Chart from "../components/Chart";
import ClientCard from "../components/ClientCard";
import { clientData } from "../packages/utils/menu";
import { homeChartData } from "../packages/utils/chart";
import EarningSummary from "../components/EarningSummary";
import Status from "../components/Status";
import Activity from "../components/Activity";
import { useRefreshToken } from "../packages/hooks/useRefreshToken";
import { useEffect } from "react";
const Home = () => {
  return (
    <section className="px-8 h-[88vh]  overflow-y-scroll  pb-5  scrollbar-thin scrollbar-thumb-cardbg ">
      <ClientCard {...clientData[0]} />
      <div className=" grid grid-cols-2 gap-5">
        {/* col 1 */}

        <Chart data={homeChartData} />

        {/* col 2 */}

        <EarningSummary />

        {/* col 3 */}

        <Status />

        {/* col-4  */}

        <Activity />

      </div>
    </section>
  );
};

export default Home;

