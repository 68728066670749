import React from "react";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";


const SupportListHeader = () => {
  return (
    <header className="grid grid-cols-[24%_11%_16%_16%_16%_16%] xl:grid-cols-[26%_11%_16%_15%_15%_15%] text-[#ABB3BF] py-3   gap-1 text-base bg-cardbg">
      <div className=" flex items-center space-x-1 xl:space-x-2  px-3 ">
        <h2>Subject</h2>
        <div className="flex flex-col  ">
          <IoIosArrowUp className="h-3 cursor-pointer text-iconColor" />
          <IoIosArrowDown className="h-3  cursor-pointer  text-iconColor" />
        </div>
      </div>
      <div className=" flex items-center space-x-1 xl:space-x-2 ">
        <h2 className="text-center">Ticket id</h2>{" "}
        <div className="flex flex-col  ">
          <IoIosArrowUp className="h-3  cursor-pointer text-iconColor" />
          <IoIosArrowDown className="h-3 cursor-pointer text-iconColor" />
        </div>
      </div>
      <div className=" text-center flex items-center space-x-1 xl:space-x-2 ">
        <h2>Regarding</h2>
        <div className="flex flex-col  ">
          <IoIosArrowUp className="h-3  cursor-pointer text-iconColor" />
          <IoIosArrowDown className="h-3 cursor-pointer text-iconColor" />
        </div>
      </div>
      <div className=" flex items-center space-x-1 xl:space-x-2 ">
        <h2 className=" w-fit min-w-fit">Date Created</h2>
        <div className="flex flex-col  ">
          <IoIosArrowUp className="h-3  cursor-pointer text-iconColor" />
          <IoIosArrowDown className="h-3 cursor-pointer text-iconColor" />
        </div>
      </div>
      <div className=" flex items-center  xl:space-x-2 ">
        <h2 className="min-w-fit">Last Updated</h2>
        <div className="flex flex-col  ">
          <IoIosArrowUp className="h-3 cursor-pointer  text-iconColor" />
          <IoIosArrowDown className="h-3 cursor-pointer text-iconColor" />
        </div>
      </div>
      <div className=" flex items-center space-x-1 xl:space-x-2 ">
        <h2>Updated By</h2>
        <div className="flex flex-col  ">
          <IoIosArrowUp className="h-3  cursor-pointer text-iconColor" />
          <IoIosArrowDown className="h-3 cursor-pointer text-iconColor" />
        </div>
      </div>
      
    </header>
  );
};

export default SupportListHeader;
