import React from 'react'
import { BiCopy } from "react-icons/bi";
const ClientCard = ({name,clientID,orders,amount,successRate,img}:any) => {
  return (
    <section className='text-white grid grid-cols-7 bg-cardbg py-3 px-4 my-5 border border-borderColor'>
         <div className='col-span-2  flex items-center space-x-4'>
            <div>
                <img src={img} alt={name} className='h-12'/>
            </div>
            <div>
                <h2 className='text-xl text-textColor'>{name}</h2>
                <p className='text-sm text-[#8F99A8] flex items-center space-x-2'> {clientID} <BiCopy className='text-lg cursor-pointer'/></p>
            </div>
         </div>
         {/* ********* */}
         <div>
            <h2 className='text-textColor text-sm'>Total Order</h2>
            <p className='text-[#EDEFF2] text-base font-medium'>{orders}</p>
         </div>
         <div>
            <h2 className='text-textColor text-sm'>Total Revenue</h2>
            <p className='text-[#EDEFF2] text-base font-medium'>{amount}</p>
         </div>
         <div className='text-textColor text-sm'>
            <h2>Success Rate</h2>
            <p className='text-[#EDEFF2] text-base font-medium'>{successRate}</p>
         </div>
        
      
{/* *********************** */}
         <div className='flex  items-center justify-end  col-span-2 px-5 text-[#2D72D2]'>
           <button>View Full Details</button>
         </div>
    </section>
  )
}

export default ClientCard