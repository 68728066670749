import React, { useRef, useState } from "react";
import { Button, FormGroup, InputGroup, Icon, Dialog } from "@blueprintjs/core";
import { FaFileCsv } from "react-icons/fa";
import { useAuthContext } from "../context/authContext";
import { routes } from "../packages/utils/routes";

const RewardHeader = ({
  btnText,
  res,
  modal,
  setModal,
  setRes,
  handleStatus,
  refetch
}: any) => {

  const { auth } = useAuthContext();
  const csvPickerRef: { current: any } = useRef();
  const [value, setValue] = useState({ csv: "" });
  const handleChange = (e: any) => {
    const value = e.target.files[0];
    const newvalue = { csv: value };
    setValue(newvalue);
  };



  return (
    <>
      <header className=" flex items-center justify-between  my-3  ">
        <div className="flex space-x-10">
          <button
            onClick={refetch}
            className="bg-cardbg text-white px-3 py-2 cursor-pointer "
          >
            Export reward
          </button>

          {/* <div className="flex ">
            <input
              ref={csvPickerRef}
              type="file"
              name="csv"
              accept={".csv"}
              hidden
              onChange={handleChange}
            />
            <button
              onClick={() => {
                csvPickerRef.current && csvPickerRef.current.click();
              }}
              className="bg-cardbg text-white px-3 py-2 cursor-pointer flex items-center space-x-3"
              type="submit"
            >
              <FaFileCsv />
              <p>Bulk Reward Status Update</p>
            </button>
          </div> */}
        </div>

        <div className=" flex items-center space-x-3 ">
          {/* input  */}
          <div className="bp4-html-select  ">
            <select
              className=""
              style={{ backgroundColor: "transparent", color: "#8F99A8" }}
            >
              <option>Filter</option>
              <option value="week">Last week</option>
              <option value="month">Last month</option>
              <option value="year">Last year</option>
            </select>

            <span
              className="bp4-icon bp4-icon-caret-down "
              style={{ color: "#8F99A8" }}
            ></span>
          </div>

          {/* button  */}
          <Button
            // onClick={() => {
            //   handleStatus(value);
            // }}
            text={btnText}
            style={{ backgroundColor: "#CD4246", color: "white" }}
          />

          {/* search  */}

          <FormGroup className=" pt-3 ">
            <div>
              <InputGroup
                id="text-input"
                placeholder="Search here"
                className="w-52 input-group "
                style={{
                  backgroundColor: "transparent",
                  border: "1px solid white",
                  color: "#C5CBD3",
                }}
                leftElement={
                  <Icon icon="user" color="#404854" className="px-2" />
                }
              />
            </div>
          </FormGroup>
        </div>
      </header>
      <div>
        <Dialog
          isOpen={ modal}
          onClose={() => {
            setModal(false);
            setRes("")
           
          }}
          className="bg-cardbg"
        >
          <div className="text-black text-center py-5">
            {/* <h2>Your import is successfull </h2>
            <h3>Shortly you will get file in your email</h3> */}
         
              {res && (
                <div className="text-lg text-white">
                  {res?.data?.data?.message || res?.message}  
                  {/* <h2>Session Timed Out</h2> */}
                </div>
              )}
          
            {/* <h2>
              {response && (
                <div className="text-lg text-white">{response?.message}</div>
              )}
            </h2> */}

            <button
              onClick={() => {
                setModal(false);
                setRes("")
               
              }}
              className="px-4 py-2 text-white mt-5 bg-bgBlack"
            >
              close
            </button>
          </div>
        </Dialog>
      </div>
    </>
  );
};

export default RewardHeader;
