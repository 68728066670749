import React from 'react'

const SingleLog = ({logData,setIsOpen,setModalLogData}:any) => {
  return (
    <>
     {logData.map((item:any, index:number) => {
            const { color, app, time, tasktitle, taskcontent, textcolor } =
              item;
            return (
              <div
                key={index}
                className="w-full  h-9 text-white grid grid-cols-[5%_5%_15%_20%_calc(55%-2rem)] xl:grid-cols-[5%_5%_10%_20%_calc(60%-2rem)] gap-2 px-4 xl:px-2 text-sm "
              >
                <div className=" flex flex-col items-center  ">
                  <div className="rounded-full  h-5 w-5 flex items-center justify-center border">
                    <div className={`${color} rounded-full h-3 w-3`}></div>
                  </div>
                  {index === logData.length - 1 ? null : (
                    <div className="h-[calc(100%-20px)] border border-[#F6F7F9]"></div>
                  )}
                </div>
                <div className=" flex text-iconColor justify-center ">
                  <h3>{time}</h3>
                </div>
                <div className=" flex ">
                  <h2>{app}</h2>
                </div>
                <div className="  ">
                  <h2 className={`${textcolor}`}>{tasktitle}</h2>
                </div>
                <div className="text-iconColor">
                  <h2
                    onClick={() => {
                      setIsOpen(true);
                      setModalLogData(taskcontent);
                    }}
                    className="truncate  hover:text-[#2F80ED] hover:underline cursor-pointer"
                  >
                    {taskcontent}
                  </h2>
                </div>
              </div>
            );
          })}
    </>
  )
}

export default SingleLog