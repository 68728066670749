import Login from "./pages/Login";
import { Routes, Route } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import Home from "./pages/Home";
import ClientList from "./pages/ClientList";
import { routes } from "./packages/utils/routes";
import CreateClient from "./pages/CreateClient";
import HealthCheck from "./pages/HealthCheck";
import AllRewards from "./pages/AllRewards";
import ClientWiseReward from "./pages/ClientWiseReward";
import Orders from "./pages/Orders";
import Log from "./pages/Log";
import Support from "./pages/Support";
import OpenNewTicket from "./components/OpenNewTicket";
import UserProfile from "./pages/UserProfile";
import UploadReward from "./pages/UploadReward";
import SystemMonitorUsage from "./pages/SystemMonitorUsage";
import SystemMonitorLog from "./pages/SystemMonitorLog";
import SystemMonitorContainer from "./pages/SystemMonitorContainer";
import SingleContainer from "./components/systemmonitor/SingleContainer";
import SharedLayout from "./components/SharedLayout";
import ProtectedRoute from "./pages/ProtectedRoute";
import UpdateRewardStatus from "./pages/UpdateRewardStatus";
import UpdateOrderStatus from "./pages/UpdateOrderStatus";

const queryClient = new QueryClient();

function App() {
  return (
    <div className="">
      <QueryClientProvider client={queryClient}>
        <Routes>
          <Route
            path={routes.home}
            element={
              <ProtectedRoute>
                <SharedLayout />
                 </ProtectedRoute>
            }
          >
            <Route index element={<Home />} />
            {/* <Route path={routes.clientlist} element={<ClientList />} /> */}
            {/* <Route path={routes.createclient} element={<CreateClient />} /> */}
            <Route path={routes.healthcheck} element={<HealthCheck />} />
            {/* <Route path={routes.allrewards} element={<AllRewards />} /> */}
            <Route path={routes.clientwise} element={<ClientWiseReward />} />
            <Route path={routes.uploadrewards} element={<UploadReward />} />
            <Route path={routes.updaterewardStatus} element={<UpdateRewardStatus />} />
            <Route path={routes.log} element={<Log />} />
            <Route path={routes.order} element={<Orders />} />
            <Route path={routes.updateorderStatus} element={<UpdateOrderStatus />} />
            <Route
              path={routes.systemmonitorUsage}
              element={<SystemMonitorUsage />}
            />
            <Route
              path={routes.systemmonitorLog}
              element={<SystemMonitorLog />}
            />
            <Route
              path={routes.systemmonitorContainer}
              element={<SystemMonitorContainer />}
            />
            <Route
              path={routes.singleContainer}
              element={<SingleContainer />}
            />
            <Route path={routes.support} element={<Support />} />
            <Route path={routes.newticket} element={<OpenNewTicket />} />
            <Route path={routes.profile} element={<UserProfile />} />
          </Route>
          <Route path={routes.login} element={<Login />} />
        </Routes>
        {/* <ReactQueryDevtools initialIsOpen={false} position="bottom-right" /> */}
      </QueryClientProvider>
    </div>
  );
}

export default App;
