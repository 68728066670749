import React from "react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
const HealthChart = ({ data,title,subtitle,color }: any) => {
  return (
    <section className=" bg-bgBlack space-y-3 py-2">
        <div className=" px-8">
            <h2 className="text-white text-base">{title} </h2>
            <h5 className="text-[#5F6B7C] text-xs">{subtitle}</h5>
        </div>
      <ResponsiveContainer width="100%" height={200} className="">
        <LineChart
          width={500}
          height={200}
          data={data}
          margin={{ top: 5, right: 30, left: 18, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="pv" stroke={color} />
        </LineChart>
      </ResponsiveContainer>
    </section>
  );
};

export default HealthChart;
