import React, { useState } from "react";
import { FaUserCircle, FaBell } from "react-icons/fa";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import { menuData } from "../packages/utils/menu";
import { MdOutlineLogout } from "react-icons/md";
import {  useNavigate } from "react-router-dom";

const Menu = ({ menu, url, submenu }: any) => {
  const [isMenuOpen, setIsMenuOpen] = useState<Boolean>(false);
  const navigate = useNavigate();

  return (
    <div className="text-[#D3D8DE] flex flex-col  ">
      <div
        className="text-[#D3D8DE] px-4 py-4  flex items-center cursor-pointer justify-between hover:bg-cardbg"
        onClick={() => {
          setIsMenuOpen(!isMenuOpen);
          if (url) {
            navigate(url);
          }
         
        }}
      >
        <h1>{menu}</h1>
        {submenu && (submenu?.length || false) > 0 ? (
          !isMenuOpen ? (
            <IoIosArrowForward className="text-lg" />
          ) : (
            <IoIosArrowDown className="text-lg" />
          )
        ) : (
          <></>
          
        )}
      </div>
      <div
        style={{
          height: isMenuOpen ? submenu?.length * 37 + "px" : 0 + "px",
        }}
        className={`transition-all overflow-hidden`}
      >
        {submenu?.map((list: any, index: any) => {
          return (
            <div
              key={index}
              onClick={() => navigate(list.url)}
              className="flex items-center  justify-between cursor-pointer py-2 pl-8 pr-4 hover:bg-cardbg"
            >
              <h2>{list.title}</h2>
            </div>
          );
        })}
      </div>

      {/* **** */}
    </div>
  );
};

export default Menu;

