import React from 'react'
import { BsPlusLg } from "react-icons/bs";

const LogFilter = () => {
  return (
    <div className=" bg-[#111418] h-[70vh] border border-borderColor p-4 space-y-10 ">
    <div className="flex items-center justify-between text-lg ">
      <h2 className="text-white font-semibold">Filters</h2>
      <BsPlusLg className="text-white rotate-45 hover:text-iconColor cursor-pointer transition-all duration-100" />
    </div>
    {/* ********* input ******* */}
    <div className="bp4-html-select  w-full ">
      <select
        className=" "
        style={{ backgroundColor: "#111418", color: "#EDEFF2", border:"1px solid #8F99A8" }}
      >
        <option >All types</option>
        <option value="week">Last week</option>
        <option value="month">Last month</option>
        <option value="year">Last year</option>
      </select>
      <span
        className="bp4-icon bp4-icon-caret-down "
        style={{ color: "#EDEFF2" }}
      ></span>
    </div>

    {/* ********************* */}
    <div className="bp4-html-select  w-full">
      <select
        className=" "
        style={{ backgroundColor: "#111418", color: "#EDEFF2", border:"1px solid #8F99A8" }}
      >
        <option >Recent</option>
        <option value="week">Last week</option>
        <option value="month">Last month</option>
        <option value="year">Last year</option>
      </select>
      <span
        className="bp4-icon bp4-icon-caret-down "
        style={{ color: "#EDEFF2" }}
      ></span>
    </div>
    {/* ********************* */}
    <div className="bp4-html-select  w-full">
      <select
        className=" "
        style={{ backgroundColor: "#111418", color: "#EDEFF2", border:"1px solid #8F99A8" }}
      >
        <option >User Data</option>
        <option value="week">Last week</option>
        <option value="month">Last month</option>
        <option value="year">Last year</option>
      </select>
      <span
        className="bp4-icon bp4-icon-caret-down "
        style={{ color: "#EDEFF2" }}
      ></span>
    </div>
    {/* ********************* */}
    <div className="bp4-html-select  w-full">
      <select
        className=""
        style={{ backgroundColor: "#111418", color: "#EDEFF2", border:"1px solid #8F99A8" }}
      >
        <option >Other</option>
        <option value="week">Last week</option>
        <option value="month">Last month</option>
        <option value="year">Last year</option>
      </select>
      <span
        className="bp4-icon bp4-icon-caret-down "
        style={{ color: "#EDEFF2" }}
      ></span>
    </div>
  </div>
  )
}

export default LogFilter