import { getLocalStorage } from './../api/login/index';
import { useState,useEffect } from "react";
import { useAuthContext } from "../../context/authContext";
import useAxiosPrivate from './useAxiosPrivate';


interface IError {
  status: boolean;
  msg: any;
}

export const useFetch = (url: string) => {
     const axiosDevClient = useAxiosPrivate()
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<IError>({ status: false, msg: "" });
  const [data, setData] = useState<any>(null);
  const {auth} = useAuthContext()
  const token = getLocalStorage()
  
  const fetch = async (signal:AbortSignal) => {
    try {
      setIsLoading(true);
      const response = await axiosDevClient.get(url,{
        signal,
        headers: {
          "access-token": token || "",
        },
        withCredentials:true
      });
    
      setData(response);
    } catch (error) {
       
      const newError = { status: true, msg: error };
      setError(newError);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(()=>{
    const controller = new AbortController()
    const signal = controller.signal
      fetch(signal)
      return ()=>{controller.abort()}
  },[])

  return {isLoading,error,data}
};
