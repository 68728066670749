import { useAuthContext } from "../../../context/authContext";

export const useLogout = () => {
  const { setAuth,setUserData } = useAuthContext();

  const logout = () => {
    setAuth(null);
    setUserData(null)
    localStorage.removeItem("token");
  };

  return logout;
};
