//  status data 

export const status = [
    {
        title:"Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        date:"12/7/2022",
        status:"bg-[#238551]"
    },
    {
        title:"Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        date:"12/7/2022",
        status:"bg-[#FBB360]"
    },
    {
        title:"Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        date:"12/7/2022",
        status:"bg-[#CD4246]"
    },
    {
        title:"Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        date:"12/7/2022",
        status:"bg-[#238551]"
    },
]

// activity data 
export const activityData = [
    {
        img:"userimage.png",
        content:"Lorem Ipsum is simply dummy text of the printing and ..."
    },
    {
        img:"userimage.png",
        content:"Lorem Ipsum is simply dummy text of the printing and ..."
    },
    {
        img:"userimage.png",
        content:"Lorem Ipsum is simply dummy text of the printing and ..."
    },
    {
        img:"userimage.png",
        content:"Lorem Ipsum is simply dummy text of the printing and ..."
    },
    {
        img:"userimage.png",
        content:"Lorem Ipsum is simply dummy text of the printing and ..."
    },


]