import React, { useState } from "react";
import SharedLayout from "../SharedLayout";
import { BiArrowBack } from "react-icons/bi";
import Chart from "./chart";
import SingleLog from "../SingleLog";
import { Dialog } from "@blueprintjs/core";
import { logData } from "../../packages/utils/logData";
import { data } from "../../packages/utils/chart";
import { useNavigate } from "react-router";
import { routes } from "../../packages/utils/routes";
import Usage from "./Usage";

// use blueprint js tabs
const subHeadingData = [
  {
    id: 0,
    title: "Usage",
  },
  {
    id: 1,
    title: "Log",
  },
];

const ChartData = [
  {
    data: data,
    title: "Activity Overview",
    color: "#9B51E0",
    uvColor: "#2D9CDB",
  },
  {
    data: data,
    title: "CPU Details ",
    color: "#9B51E0",
    uvColor: "#2D9CDB",
  },
  {
    data: data,
    title: "Network Details ",
    color: "#9B51E0",
    uvColor: "#2D9CDB",
  },
  {
    data: data,
    title: "Storage Details ",
    color: "#9B51E0",
    uvColor: "#2D9CDB",
  },
];

const SingleContainer = () => {
  const [page, setPage] = useState("Usage");
  const [isOpen, setIsOpen] = useState(false);
  const [modalLogData, setModalLogData] = useState("");
  const navigate = useNavigate();
  return (
    <>
      <section className="px-8 py-5 text-white">
        <div className=" space-y-3">
          <div
            onClick={() => navigate(routes.systemmonitorContainer)}
            className=" w-fit flex items-center text-lg space-x-2 cursor-pointer hover:text-[#2F80ED]"
          >
            <BiArrowBack className="" />
            <h2>back</h2>
          </div>
          {/* ****************** */}
          <div className="flex items-center space-x-3">
            <div>
              <img src="/userimage.png" alt="backened service image" />
            </div>
            <div>
              <h2 className="text-lg">MongoDB</h2>
              <h5 className="text-[#8F99A8]">2hr 3min</h5>
            </div>
          </div>
        </div>
      </section>

      <div className="border-b border-borderColor text-white px-8 text-lg   ">
        <div className="flex space-x-14  ">
          {subHeadingData.map((item, index) => {
            const { title, id } = item;
            return (
              <div
                onClick={() => setPage(title)}
                className=" px-3  group cursor-pointer "
              >
                <h2 className="group-hover:text-[#2F80ED]">{title}</h2>
                <div className="w-full border-2  border-transparent group-hover:border-[#2F80ED] "></div>
              </div>
            );
          })}
        </div>
      </div>
      {page === "Usage" ? (
        <div className="  h-[60vh]  overflow-y-scroll  scrollbar-thin py-5 px-8">
          <Usage data={ChartData} />
        </div>
      ) : (
        <div className="py-5 px-6">
          <SingleLog
            logData={logData}
            setIsOpen={setIsOpen}
            setModalLogData={setModalLogData}
          />
          {/* modal  */}
          <div>
            <Dialog
              className="bg-cardbg"
              isOpen={isOpen}
              onClose={() => setIsOpen(false)}
            >
              <div className="text-center p-5 space-y-5">
                <h2 className="text-white text-base">{modalLogData} </h2>
                <button
                  onClick={() => setIsOpen(false)}
                  className="py-2 px-5 text-white bg-bgBlack "
                >
                  close
                </button>
              </div>
            </Dialog>
          </div>
        </div>
      )}
    </>
  );
};

export default SingleContainer;
