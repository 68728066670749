import { getLocalStorage } from "./../login/index";
import { apiRoutes } from "../../constants/apiconst";
import { axiosDevClient } from "..";

const token = getLocalStorage();

export const exportOrders = async (values: any) => {
  try {
    const response = await axiosDevClient.get(
      `${apiRoutes.orders.exportOrders}?from=${values.from}&to=${values.to}`,
      {
        headers: {
          "access-token": token || ""
        },
        withCredentials:true
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};


