import React from 'react'
import { status } from '../packages/utils/status'
const Status = () => {
  return (
    <div>

    <div className="text-white text-sm  border border-borderColor  h-fit">
    {/* top  */}
    <div className="flex items-center justify-between py-3 px-5 bg-[]">
      <div className="w-[60%]">
        <h2>Title</h2>
      </div>
      <div className="w-[40%] flex items-center justify-between  pl-3">
        <h2 className="">Date</h2>
        <h2>Status</h2>
      </div>
    </div>
    {/* body  */}
    {status.map((item,index)=>{
        return <div key={index} className="flex items-center justify-between  border border-borderColor px-5 py-2 bg-cardbg">
        <div className="w-[60%] xl:w-[55%]">
          <h2>{item.title}</h2>
        </div>
        <div className="w-[40%] flex items-center justify-between  lg:pr-3">
          <h2>{item.date}</h2>
          <div className={`w-4 h-4 ${item.status}`} ></div>
        </div>
      </div>
    })}
  </div>
    {/* color types  */}
    <div className='flex items-center space-x-2 px-3 pt-3 text-white'>
      <div className='flex space-x-2 items-center text-xs'>
        <div className='h-2 rounded-full bg-[#238551] w-2'></div>
         <h2>Success</h2>
      </div>
      <div className='flex space-x-2 items-center text-xs'>
        <div className='h-2 rounded-full bg-[#CD4246] w-2'></div>
         <h2>Error</h2>
      </div>
      <div className='flex space-x-2 items-center text-xs'>
        <div className='h-2 rounded-full bg-[#FBB360] w-2'></div>
         <h2>Pending</h2>
      </div>
    </div>
    </div>
  )
}

export default Status