import React, { useState } from "react";
import { Icon, IconSize } from "@blueprintjs/core";
import { FaBell } from "react-icons/fa";
import { FormGroup, InputGroup, Button } from "@blueprintjs/core";
import { useNavigate } from "react-router";
import { routes } from "../packages/utils/routes";
import { FaTimes } from "react-icons/fa";

const notificationData = [
  {
    content:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Deserunt",
  },
  {
    content:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Deserunt",
  },
  {
    content:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Deserunt",
  },
  {
    content:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Deserunt",
  },
  {
    content:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Deserunt",
  },
  {
    content:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Deserunt",
  },
  {
    content:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Deserunt",
  },
  {
    content:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Deserunt",
  },
];

const Navbar = () => {
  const [isShowNotification,setShowNotification] = useState(false)
  const navigate = useNavigate();
  return (
    <header className=" pl-8  pr-8 pt-5 pb-1 flex justify-between border-b-[1.5px] border-[#D3D8DE]">
      <FormGroup className=" ">
        <InputGroup
          id="text-input"
          placeholder="Search here"
          className="w-80 focus:outline-none bg-transparent placeholder-[#404854]"
          style={{
            backgroundColor: "transparent",
            border: "1px solid white",
            color: "#C5CBD3",
          }}
          leftElement={<Icon icon="search" color="#404854" className="px-2" />}
        />
      </FormGroup>
      <div className=" flex text-iconColor text-xl space-x-3 px-2">
        {/* <FormGroup className=" focus:outline-none focus:shadow-none focus-visible:outline-none focus-visible:shadow-none ">
          <div>
            <InputGroup
              id="text-input"
              placeholder="Search client"
              className="w-52 input-group "
              style={{
                backgroundColor: "transparent",
                border: "1px solid white",
                color: "#C5CBD3",
              }}
              leftElement={
                <Icon icon="user" color="#404854" className="px-2" />
              }
              rightElement={
                <Icon
                  icon="arrow-right"
                  color="#404854"
                  className="px-2 py-2"
                />
              }
            />
          </div>
        </FormGroup> */}
        <FaBell onClick={()=>setShowNotification(!isShowNotification)} className="cursor-pointer  mt-1 hover:scale-110" />
        {
          isShowNotification &&  <div className=" absolute z-50 bg-cardbg h-[172px] w-72 right-8 top-16 px-3 py-2 text-white space-y-1">
          <div className="flex justify-between items-center">
            <h2 className="text-base">Notifications</h2>
            <FaTimes onClick={()=>setShowNotification(false)} className="text-iconColor text-base hover:text-bgBlack cursor-pointer" />
          </div>
          <div className=" overflow-y-scroll scrollbar-none  space-y-2 h-[128px] ">
            {notificationData.map((item, index) => {
              return (
                <h2
                  key={index}
                  className="truncate text-sm cursor-pointer text-white hover:text-[#2F80ED]"
                >
                  {item.content}{" "}
                </h2>
              );
            })}
          </div>
        </div>
        }
       
      </div>
    </header>
  );
};

export default Navbar;

{
  /* <div className="bp4-form-content">
    <div className="bp4-input-group ">
      <span className="bp4-icon bp4-icon-search fill-blue-700"></span>
      <input id="form-group-input" type="text"  className="bp4-input border focus:outline-none border-borderColor"
             style={{width: "300px"}} placeholder="Search here" dir="auto" />
    </div>
  </div>
  {/* second search bar  */
}
//  <div className="bp4-form-content">
//     <div className="bp4-input-group ">
//       <span className="bp4-icon bp4-icon-search"></span>
//       <input id="form-group-input" type="text"  className="bp4-input border  focus:outline-none border-borderColor"
//              style={{width: "150px"}} placeholder="Search here" dir="auto" />
//     </div>
//   </div> */}
