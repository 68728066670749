import React, { useState } from "react";
import FormInput from "./FormInput";

const ProfileInfoForm = (props: { password: boolean }) => {
  const { password } = props;
  const [values, setValues] = useState({
    first:"",
    last: "",
    email: "",
    number: "",
    password: "",
    confirmpassword: "",
  });

  const handleChange = (e: any) => {
    let name = e.target.name;
    let value = e.target.value;
    const newValues = { ...values, [name]: value };
    setValues(newValues);
  };

  const handleCancel = () => {
    const newValues = {
      first: "",
      last: "",
      email: "",
      number: "",
      password: "",
      confirmpassword: "",
    };

    setValues(newValues);
  };

  const handleSubmit = (e:any)=>{
    e.preventDefault()
  }
  return (
    <form onSubmit={handleSubmit} className=" h-full  ">
      <div className=" h-[80%]  ">
        {!password && (
          <div className="space-y-3">
            {/* personal info form  */}
            {/* first name  */}
            <div className="flex space-x-20">
              <FormInput
                type="text"
                value={values.first}
                onChange={handleChange}
                name="first"
                placeholder="First Name"
              />
              <FormInput
                type="text"
                value={values.last}
                onChange={handleChange}
                name="last"
                placeholder="Last Name"
              />
            </div>
            <div className="flex space-x-20">
              <FormInput
                type="email"
                value={values.email}
                onChange={handleChange}
                name="email"
                placeholder="Email ID"
              />
              <FormInput
                type="number"
                value={values.number}
                onChange={handleChange}
                name="number"
                placeholder="Phone Number"
              />
            </div>
          </div>
        )}

        {password && (
          <div>
            {/* password form  */}
            <div className="flex space-x-20">
              <FormInput
                type="password"
                value={values.password}
                onChange={handleChange}
                name="password"
                placeholder="New Password"
              />
              <FormInput
                type="password"
                value={values.confirmpassword}
                onChange={handleChange}
                name="confirmpassword"
                placeholder="Confirm New Password"
              />
            </div>
          </div>
        )}
      </div>
      <div className=" px-5 flex justify-end text-white text-xs space-x-5">
        <button
          onClick={handleCancel}
          type={"reset"}
          className="px-4 py-2 bg-cardbg"
        >
          Cancel
        </button>
        <button type={"submit"} className="bg-[#2F80ED] py-2 px-8">
        Update
        </button>
      </div>
    </form>
  );
};

export default ProfileInfoForm;
