import React, { useRef, useState } from "react";
import { MdNavigateNext } from "react-icons/md";
import RewardHeader from "../components/RewardHeader";
import Spinner from "../components/Spinner";
import { useAuthContext } from "../context/authContext";
import { exportReward } from "../packages/api/rewards";
import { useFetch } from "../packages/hooks/useFetch";
import { apiRoutes } from "../packages/constants/apiconst";
import { allRewards } from "../packages/utils/rewards"

const pagination = [1, 2];

const ClientWiseReward = () => {
  const { auth } = useAuthContext()
  const [isExportLoading, setIsExportLoading] = useState<boolean>(false)
  const [response, setResponse] = useState<any>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [page, setPage] = useState<number>(1)
  const scrollRef: { current: any } = useRef();
  const { isLoading, error, data } = useFetch(`${apiRoutes.rewards.getAllRewards}?page=${page}`)


  const handleExportReward = () => {
    setIsExportLoading(true)
    exportReward(auth).then((res) => {
      setIsOpen(true)
      setResponse(res)
      setIsExportLoading(false)
    }).catch((err) => {
      setIsOpen(true)
      setResponse(err)
      setIsExportLoading(false)
    })
  }

  const scrollTop = () => {
    scrollRef?.current?.scrollTo(0, 10);
  };

  const handlePageInc = () => {
    if (page < pagination.length) {
      setPage((prev) => prev + 1);
    } else if (page >= pagination.length) {
      setPage(1);
    }
  };
  const handlePageDec = () => {
    if (page > 1) {
      setPage((prev) => prev - 1);
    } else if (page == 1) {
      setPage(pagination.length);
    }
  };

  if (isExportLoading || isLoading) {
    return <Spinner />;
  }

  return (
    <section ref={scrollRef} className="px-8 h-[88vh]  overflow-y-scroll  pb-5 scrollbar-thin scrollbar-thumb-cardbg">
      <RewardHeader
        // handleStatus={handleBulkRewardStatusUpdate} 
        btnText="Active Client Reward"
        res={response}
        setRes={setResponse}
        modal={isOpen}
        setModal={setIsOpen}
        refetch={handleExportReward}
      />

         { 
         error.status ? <div className="flex items-center justify-center h-[60vh] ">
          <div className="text-white text-xl ">{error?.msg.message}</div>
        </div> : <> <table className="w-full text-white">
          <thead>

        <tr className="">
          <th className="text-left pl-2">Reward Name</th>
          <th className="text-left">Date</th>
          <th className="text-left">Points</th>
          <th className="text-left">Status</th>
        </tr>
          </thead>

        {
          data?.data.data.results.length > 0 && data?.data.data.results.map((item:any, ind:number) => {
            return <tr key={ind} className="">
              <td className=" w-96">
                <div className="flex items-center space-x-3">
                  <div className="py-1">
                    <img src={item.image} alt={item.title} className="h-10 " />
                  </div>
                  <h2 className="text-sm">{item.title}</h2>
                </div>
              </td>
              <td className="">
                <h2>153</h2>
              </td>

              <td className="">
                <h2>{item?.points}</h2>
              </td>

              <td className="">
                <div className="space-x-8 ">
                  <button className={`${item.is_active ? "bg-[#238551]" : "bg-transparent"} text-white px-5 py-1 border border-[#238551]`}>
                    Active
                  </button>
                  <button className={`${item.is_active ? "bg-transparent" : "bg-[#CD4246]"} text-white px-5 py-1 border border-[#CD4246]`}>
                    Inactive
                  </button>
                </div>
              </td>
            </tr>
          })
        }
      </table>

      {/* no rewards section  */}
      {data?.data.data.results.length === 0 && (
            <section>
              <div className="bp4-non-ideal-state  h-[60vh]">
                <div className="bp4-non-ideal-state-text">
                  <h4 className="bp4-heading" style={{ fontSize: "25px" }}>
                    No Rewards are here yet
                  </h4>
                </div>
              </div>
            </section>
          )}

          {/* pagination  */}
          {data?.data.data.results.length !== 0 && <div className=" text-white flex space-x-2 py-3 items-center">
            <button>
              <MdNavigateNext
                onClick={() => {
                  handlePageDec();
                  scrollTop();
                }}
                className=" text-2xl rotate-180 cursor-pointer"
              />
            </button>

            {pagination.map((item: number, index: number) => {
              return (
                <div
                  key={index}
                  onClick={() => {
                    setPage(item);
                    scrollTop();
                  }}
                  className={`${page === item ? "bg-borderColor" : ""
                    } py-1 px-3 bg-cardbg cursor-pointer`}
                >
                  <h2>{item}</h2>
                </div>
              );
            })}
            <button>
              <MdNavigateNext
                onClick={() => {
                  handlePageInc();
                  scrollTop();
                }}
                className=" text-2xl cursor-pointer"
              />
            </button>
          </div>} 
      </>
}

    </section>
  );
};

export default ClientWiseReward;
