import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { useAuthContext } from "../../../context/authContext";
import { apiRoutes } from "../../constants/apiconst";
import { axiosDevClient, axiosDevClientLogin } from "..";
const generateTokenHeaders = {
  "x-client-host": "123456",
  "x-client-location": "",
};

interface IData {
  email: string;
  password: string | number;
}

export const getLocalStorage = () => {
  let accesstoken = localStorage?.getItem("token");
  if (accesstoken) {
    let newAccesstoken: any;
    return (newAccesstoken = localStorage?.getItem("token") ?? "");
  } else {
    return "";
  }
};

// ********Login user *************
export const loginStoreUser = async (data: IData) => {
  try {
    const res = await axiosDevClientLogin.post(
      `${apiRoutes.login.loginStoreUser}`,
      data
    );
    return res;
  } catch (error) {
    throw error;
  }
};

// *******generate token **********

export const generateToken = async (token: string | number) => {
  try {
    const res = await axiosDevClientLogin.post(
      `${apiRoutes.login.generateToken}`,
      { auth_code: token },
      { headers: { ...generateTokenHeaders } }
    );
    return res;
  } catch (error) {
    throw error;
  }
};


// export const useRefreshToken = ()=>{
//  const {auth,setAuth} = useAuthContext()
//  const refresh = async ()=>{
//    const response = await axiosDevClient.get(`${baseURL}store/user/get-access-token`,{
//     headers:{
//       "access-token":localStorage.getItem("token") || ""
//     },
//     withCredentials:true
//   })
//    console.log(response,"refresh")
//  }
//   return refresh
// }
