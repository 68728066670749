import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
const Chart = (props: { data: any }) => {
  return (
    <div>
      <div className=" flex items-center justify-between px-10 py-3">
        <h2 className="text-white text-lg">Charts</h2>
        <div className="text-[#8F99A8] text-base space-x-1">
          <span className="cursor-pointer">Day | </span>
          <span className="cursor-pointer">Week | </span>
          <span className="cursor-pointer">Month</span>
        </div>
      </div>
      <div className=" ">
        <ResponsiveContainer width="100%" height={200}>
          <BarChart width={500} height={200} data={props.data} className="">
            <XAxis dataKey="name" stroke="#8F99A8" />
            <YAxis stroke="#8F99A8" />
            {/* <Tooltip cursor={false} wrapperStyle={{ color:"#1E1E1E" }} /> */}
            <CartesianGrid
              stroke="#ccc"
              strokeDasharray="5 5"
              vertical={false}
            />
            <Bar dataKey="uv" fill="#DCE0E5" barSize={50} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default Chart;
