import jwt_decode from "jwt-decode";
import { useEffect, useState } from "react";
import { useAuthContext } from "../../context/authContext";
import { getLocalStorage } from "../../packages/api/login";

export const useCheckToken = () => {
  const { setAuth } = useAuthContext();
  const token = getLocalStorage();
  useEffect(()=>{
    setAuth(token);

  },[token])
  if (token) {
    const decoded = jwt_decode(token || "");
    return decoded;
  }
};

// const decoded = jwt_decode(token || '')
