import React from 'react'
import Chart from './chart'

const Usage = ({data}:any) => {
  return (
    <div className="grid grid-cols-2 gap-8">
          
        {data.map((item:any,index:number)=>{
            return <Chart key={index} {...item}/>
          })}
      
          
          
        </div>
  )
}

export default Usage