import { getLocalStorage } from './../api/login/index';
import { apiRoutes } from './../constants/apiconst';
import { axiosDevClientLogin } from './../api/index';
import { useAuthContext } from './../../context/authContext';

export const useRefreshToken = ()=>{
    const {setAuth} = useAuthContext()
    const token =  getLocalStorage()

    const refresh = async ()=>{
        const response = await axiosDevClientLogin.get(`${apiRoutes.login.refreshToken}`,{
            headers:{
                "access-token": token || ""
            },
            withCredentials:true
        })
         const accesstoken = response.data.data.access_token
         setAuth(accesstoken)
         localStorage.setItem("token",accesstoken)
        return accesstoken
    }

    return refresh
}
