import React from "react";
import { MethodSignature } from "typescript";

const FormInput = (props:{type:string,value:string | number,onChange:any,name:string,placeholder:string}) => {
    const {type,value,onChange,name,placeholder} = props
  return (
    <div className="bp4-form-group ">
      <div className="bp4-form-content">
        <div className="bp4-input-group r lg:w-[180px] xl:w-[260px]">
          <input
            type={type}
            className="bp4-input"
            style={{
              // width: "270px",
              backgroundColor: "#111418",
              border: "1px solid #8F99A8",
              color: "#ffffff",
            }}
            placeholder={placeholder}
            name={name}
            value={value}
            onChange={onChange}
            dir="auto"
          />
        </div>
      </div>
    </div>
  );
};

export default FormInput;
