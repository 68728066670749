// Create all the Axios API Client here
import axios from "axios";
const defaultTestHeaders = {
  "x-api-key": process.env.REACT_APP_API_KEY || false,
  "x-api-secret": process.env.REACT_APP_API_SECRET || false,
};

export const axiosDevClient = axios.create({
  headers: process.env.NODE_ENV === "development" ? defaultTestHeaders : {},
  withCredentials: true,
});

export const axiosDevClientLogin = axios.create({
    withCredentials: true,
  });
