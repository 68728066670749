import { Button, Dialog, NonIdealState } from "@blueprintjs/core";
import React, { useEffect, useRef, useState } from "react";
import { FaFileCsv, FaImages } from "react-icons/fa";
import Spinner from "../components/Spinner";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { usePost } from "../packages/hooks/usePost";
import { apiRoutes } from "../packages/constants/apiconst";

const UploadReward = () => {
  const csvPickerRef: { current: any } = useRef();
  const zipPickerRef: { current: any } = useRef();
  const [values, setValues] = useState<any>({
    csv: null,
    zip: null,
  });
  const formData = new FormData()
  const [formState, setFormState] = useState(formData)
  const {isLoading,error,data,post,isOpen,response,setisOpen,setResponse} = usePost()

  const handleChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.files[0];

    setFormState((formState) => {
      formState.append(name, value)
      return formState
    })

    setValues({...values,[name]:value})
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    post(`${apiRoutes.rewards.bulkRewardUpload}`,formState)
    setFormState(formData)
    const newValues = {csv:null,zip:null}
    setValues(newValues)
  };


  if (isLoading) {
   return  <Spinner />
  }


  return (
    <section className="flex items-center justify-center h-[88vh] scrollbar-thin scrollbar-thumb-cardbg">
      <div className="text-center text-white  bg-[#111418] w-[50%] xl:w-[40%]  py-10 space-y-5">
        <div className="space-y-2">
          <h2 className="text-xl">Upload Bulk Rewards</h2>
         
        </div>

        <form onSubmit={handleSubmit} className="space-y-7">
          <div className="space-y-3">

            <div className="flex  items-center justify-center space-x-5">
              <div className="">
                <input
                  ref={csvPickerRef}
                  type="file"
                  name="csv"
                  accept={".csv"}
                  hidden
                  onChange={handleChange}
                />
                <div
                  onClick={() =>
                    csvPickerRef.current && csvPickerRef.current.click()
                  }
                  className="bg-cardbg px-3 py-2 cursor-pointer flex items-center space-x-2"
                >
                  <FaFileCsv />
                  <p>Choose csv file</p>
                </div>
              </div>
              <div>
                <input
                  ref={zipPickerRef}
                  type="file"
                  name="zip"
                  hidden
                  accept=".zip,.rar,.7zip"
                  onChange={handleChange}
                />
                <div
                  onClick={() =>
                    zipPickerRef.current && zipPickerRef.current.click()
                  }
                  className="bg-cardbg px-3 py-2 cursor-pointer flex items-center space-x-2"
                >
                  <FaImages />
                  <p>Choose image zip file</p>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center justify-center">
              {
                values.csv !== null && <div className=' flex items-center space-x-2'>
                  <AiOutlineCheckCircle className='text-lg' />
                  <span className='text-gray-400'>{values.csv.name}</span>
                </div>
              }
              {
                values.zip !== null && <div className=' flex items-center space-x-2'>
                  <AiOutlineCheckCircle className='text-lg' />
                  <span className='text-gray-400'>{values.zip.name}</span>
                </div>
              }
            </div>

          </div>
          <div className="">
            <button
              type="submit"
              className="border border-[#2D72D2] text-[#2D72D2] px-4 py-2"
            >
              Upload Rewards
            </button>
          </div>
        </form>
      </div>
      <Dialog
        isOpen={isOpen}
        onClose={() =>{
          setisOpen(false)
          setResponse(null)
          setFormState(formData)
        }}
        className="bg-cardbg"
      >
        <div className="text-white text-center py-5">
          {response && <h2 className="text-lg">{response?.data?.data?.message || response?.message}</h2>}

          <button
            onClick={() =>{
              setisOpen(false)
              setResponse(null)
          setFormState(formData)
            } }
            className="bg-bgBlack px-4 py-2 text-white mt-5 "
          >
            close
          </button>
        </div>
      </Dialog>
    </section>
  );
};

export default UploadReward;
//
