import { useEffect, useState } from "react";
import { FormGroup, InputGroup, Button } from "@blueprintjs/core";
import { loginStoreUser, generateToken } from "../packages/api/login";
import { useNavigate } from "react-router";
import { useAuthContext } from "../context/authContext";
import { EyeOn } from "@blueprintjs/icons/lib/esm/generated/16px/paths";
import axios from "axios";

const Login = () => {
  const navigate = useNavigate();
  const { auth, error, setError, setAuth } = useAuthContext();
  const [isShowPassword, setIsShowPassword] = useState(false)
  const [isLoading,setIsLoading]=useState<boolean>(false)

  const [values, setValues] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    const newValues = { ...values, [name]: value };
    setValues(newValues);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    // loginUser(values);
    setIsLoading(true)
    loginStoreUser(values).then((res) => {
      const authCode = res?.data.data.auth_code
      generateToken(authCode).then((data) => {
        const token = data?.data.success.access_token
        axios.defaults.headers.common['access-token'] = token
        window.localStorage.setItem("token", token)
        setAuth(token)
        navigate("/");
        setIsLoading(false)
      }).catch((err) => {
        setError(true)
        setIsLoading(false)
      })

    }).catch((err)=>{
      setError(true)
      setIsLoading(false)
    })

    const newValues = { email: "", password: "" };
    setValues(newValues);
  };


  useEffect(() => {
    setTimeout(() => {
      setError(false)
    }, 4000)
  }, [error])

 
  return (
    <section className="grid grid-cols-2 h-[100vh] ">
      {/* left  */}
      <div className="relative flex items-center justify-center bg-[#2F343C] overflow-hidden ">
        {/* top circle background  */}
        <div className="h-80 w-80 absolute z-50 rounded-full -top-28 -right-28  inner-circle"></div>
        <div className="h-80 w-80 absolute rounded-full -top-32 right-0  inner-circle"></div>

        <div className="flex space-x-3  items-center">
          <div>
            <img src="logo.png" alt="logo" className="h-9" />
          </div>

          <h6 className="font-semibold text-white text-lg">My Accoladez</h6>
        </div>

        {/* top circle background  */}
        <div className="h-80 w-80 absolute z-50 rounded-full -bottom-36 -left-28  inner-circle"></div>
        <div className="h-80 w-80 absolute rounded-full -bottom-56 left-14  inner-circle"></div>
      </div>

      {/* right  */}
      <div className="flex items-center bg-white">
        <div className="flex flex-col w-[60%] mx-auto space-y-3">
          <div>
            <h4 className="bp4-heading text-[#1C2127]">
              Login to manage your client
            </h4>
            <p className="bp4-text-small text-[#738091]">
              Lorem Ipsum is simply dummy text.
            </p>
          </div>
          {error && (
            <div className=" text-center bg-red-500 text-white py-2">
              <h2>Incorrect username and password</h2>
            </div>
          )}

          <form onSubmit={handleSubmit} className=" relative">
            <FormGroup className="">
              <InputGroup
                placeholder="Enter your ID"
                required
                className="py-5"
                name="email"
                value={values.email}
                onChange={handleChange}
              />
              <InputGroup
                placeholder="Enter your password"
                required
                type={isShowPassword ? "text" : "password"}
                className=""
                name="password"
                value={values.password}
                onChange={handleChange}
              />
              <span onClick={() => setIsShowPassword(!isShowPassword)} className={`${isShowPassword ? "bp4-icon-eye-off" : "bp4-icon-eye-on"} bp4-icon  absolute right-3 top-20 `}></span>
              <div className="text-right text-xs py-2 cursor-pointer text-[#2D72D2] ">
                Forgot Pasword?
              </div>
            </FormGroup>

            <Button
              disabled={isLoading}
              type="submit"
              intent="primary"
              text={(isLoading ) ? "Loading..." : "Login"}
              className="w-full"
            />
          </form>
        </div>
      </div>
    </section>
  );
};

export default Login;
