import { useEffect } from 'react';
import { useAuthContext } from './../../context/authContext';
import { useRefreshToken } from './useRefreshToken';
import { axiosDevClient } from '../api';


const useAxiosPrivate = ()=>{
    const refresh = useRefreshToken()
    const {auth} = useAuthContext()

    useEffect(()=>{
        const requestIntercept = axiosDevClient.interceptors.request.use(
            (config) => {
               if (auth && config?.headers) {
                config.headers["access-token"] = auth
               }
               return config
            },
            (error) => {
                return Promise.reject(error)
            }
        )

        const responsIntercept = axiosDevClient.interceptors.response.use(
            (res)=>{
                return res
            },
            async (error)=>{
                const prevRequest = error?.config
                if (error?.response?.status === 401 && !prevRequest.sent) {
                    prevRequest.sent = true
                    const newAccesstoken = await refresh()
                    prevRequest.headers["access-token"] = newAccesstoken
                    return  axiosDevClient(prevRequest)
                }
                return Promise.reject(error)
            }
        )

     return ()=>{
        axiosDevClient.interceptors.request.eject(requestIntercept)
        axiosDevClient.interceptors.response.eject(responsIntercept)
     }

    },[refresh,auth])

    return axiosDevClient

}

export default useAxiosPrivate