import axios from "axios";
// import { axiosDevClient } from './../api/index';
import { useState } from "react";
import { useAuthContext } from "../../context/authContext";
import useAxiosPrivate from "./useAxiosPrivate";

interface IError {
  status: boolean;
  msg: any;
}

export const usePost = () => {
   const axiosDevClient =  useAxiosPrivate()
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<IError>({ status: false, msg: "" });
  const [data, setData] = useState<any>(null);
  const [isOpen, setisOpen] = useState<boolean>(false);
  const [response, setResponse] = useState<any>(null);
  const {auth} = useAuthContext()

  const post = async (url:string,postData:any) => {
    try {
      setIsLoading(true);
      const response = await axiosDevClient.post(url,postData,{
        headers: {
          "access-token": auth || "",
          "Content-Type": "multipart/form-data",
        },
        withCredentials:true
      });
      setData(response);
      setResponse(response)
      setisOpen(true)
    } catch (error) {
      const newError = { status:true, msg:error };
      setError(newError);
      setResponse(error)
      setisOpen(true)
    } finally {
      setIsLoading(false);
    }
  };
  return {isLoading,error,data,post,isOpen,response,setisOpen,setResponse}
};