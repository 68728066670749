import { createContext, useContext, useEffect, useState } from "react";
import jwtDecode from "jwt-decode";

const AuthContext = createContext<any | null>(null);

export const AuthProvider = (props: { children: JSX.Element | JSX.Element[] }) => {
  const [auth, setAuth] = useState(null);
  const [error,setError]= useState(false)
 const [userData,setUserData] = useState<any>(null)
useEffect(() => {
  if (auth) {
    let user = jwtDecode(auth || "")
    setUserData(user)
  }

 
}, [auth])

  return (
    <AuthContext.Provider value={{ auth, setAuth ,setError,error,userData,setUserData}}>
      {props.children}
    </AuthContext.Provider>
  );
};


export const useAuthContext = ()=>{
return  useContext(AuthContext)
}
