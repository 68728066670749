import React from 'react'
import HealthChart from '../components/healthcheck/HealthChart'
import SharedLayout from '../components/SharedLayout'
import Chart from '../components/systemmonitor/chart';
import Usage from '../components/systemmonitor/Usage';


const data = [
    {
      name: "Page A",
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: "Page B",
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: "Page C",
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: "Page D",
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: "Page E",
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: "Page F",
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
  ];
  
  const ChartData = [
    {
      data:data,
      title:"Activity Overview",
      color:"#9B51E0",
      uvColor:"#2D9CDB"
    },
    {
      data:data,
      title:"CPU Details ",
      color:"#9B51E0",
      uvColor:"#2D9CDB"
    },
    {
      data:data,
      title:"Network Details ",
      color:"#9B51E0",
      uvColor:"#2D9CDB"
    },
    {
      data:data,
      title:"Storage Details ",
      color:"#9B51E0",
      uvColor:"#2D9CDB"
    },
  ]

const SystemMonitorUsage = () => {
  return (
        <section className='px-8 py-8 h-[88vh] overflow-y-scroll scrollbar-thin scrollbar-thumb-cardbg'>
          <Usage data={ChartData}/>
        </section>
    )
}

export default SystemMonitorUsage