import { useContext, useRef, useState } from "react";
import { Button, Dialog } from "@blueprintjs/core";
import {  exportOrders } from "../packages/api/allorders";
import { MdNavigateNext } from "react-icons/md";
import Spinner from "../components/Spinner";
import { BiExport } from "react-icons/bi";
import { useFetch } from "../packages/hooks/useFetch";
import { apiRoutes } from "../packages/constants/apiconst";
import { allRewards } from "../packages/utils/rewards";

const pagination = [1, 2];
interface IQuery {
  isLoading: boolean;
  isError: boolean;
  error: any;
  data: any;
}

const Orders = () => {
  const [isExportOrderLoading,setIsExportOrderLoading] = useState<boolean>(false)
  const [page, setPage] = useState(1);
  const scrollRef: { current: any } = useRef();
  const [response, setResponse] = useState<any>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [date, setDate] = useState<any>({
    from: "",
    to: ""
  })
const {isLoading,error,data} = useFetch(`${apiRoutes.orders.getAllOrders}?page=${page}`)

  const handleDateChange = (e: any) => {
    const name = e.target.name
    const value = e.target.value
    const newValues = { ...date, [name]: value }
    setDate(newValues)
  }

  const handleExportOrder = ()=>{
    setIsExportOrderLoading(true)
        exportOrders(date).then((res)=>{
          setIsOpen(true)
          setResponse(res)
          setIsExportOrderLoading(false)
        }).catch((err)=>{
          setIsOpen(true);
          setResponse(err);
          setIsExportOrderLoading(false)
        })
  }
  const scrollTop = () => {
    scrollRef?.current?.scrollTo(0, 10);
  };

  const handlePageInc = () => {
    if (page < pagination.length) {
      setPage((prev) => prev + 1);
    } else if (page >= pagination.length) {
      setPage(1);
    }
  };
  const handlePageDec = () => {
    if (page > 1) {
      setPage((prev) => prev - 1);
    } else if (page == 1) {
      setPage(pagination.length);
    }
  };

  if (isLoading || isExportOrderLoading) {
    return (
      <Spinner />
    );
  }

  // if (error.status) {
  //   return (
  //     <div className="flex items-center justify-center h-[88vh]">
  //       <div className="text-white text-xl ">{error?.msg.message}</div>
  //     </div>
  //   );
  // }


  return (
    <>
      <section
        ref={scrollRef}
        className="px-8 h-[88vh]  overflow-y-scroll  pb-5 scrollbar-thin scrollbar-thumb-cardbg"
      >
        <div className="flex items-center justify-between  py-5 px-2">
          <h1 className="text-white text-xl">All orders</h1>
          <div className="space-x-6  flex items-center ">
            {/* input  */}
            <div className="bp4-html-select  ">
              <select
                className=""
                style={{ backgroundColor: "transparent", color: "#8F99A8" }}
              >
                <option >Filter</option>
                <option value="week">Last week</option>
                <option value="month">Last month</option>
                <option value="year">Last year</option>
              </select>
              <span
                className="bp4-icon bp4-icon-caret-down "
                style={{ color: "#8F99A8" }}
              ></span>
            </div>
            <div className=" flex space-x-3 items-center">
              <label htmlFor="from" className="text-white font-semibold">From</label>
              <input onChange={handleDateChange} className=" py-1 text-sm px-1" value={date.from} type="date" name="from" id="from" />
            </div>
            <div className=" flex space-x-3 items-center">
              <label htmlFor="to" className="text-white font-semibold">To</label>
              <input onChange={handleDateChange} className=" py-1 text-sm px-1" value={date.to} type="date" name="to" id="to" />
            </div>
            {/* export btn  */}
            <button onClick={handleExportOrder} className=" bg-cardbg px-3 py-2 text-white flex items-center space-x-2">
              <BiExport className="text-lg" />
              <span>Export Orders</span>
            </button>
          </div>
        </div>

        <table className="text-white w-full text-left ">
          <thead className="py-2">
            <tr>
              
            <th className="pl-2">Reward Title</th>
            <th>Docket</th>
            <th className="">Points</th>
            <th>Status</th>
            </tr>
            
          </thead>
          {
           data?.data.data.results?.map((item: any, index: number) => {
              // const { order_status: status } = item;
              return (<tr className="">
                <td className="w-96 ">
                  <div className=" flex items-center space-x-3 py-1 ">
                    <div className=" w-12  ">
                      <img
                        src={item?.image}
                        alt={`${item?.title || "image"}`}
                        className="h-10 w-12 object-contain"
                      />
                    </div>
                    <h2 className="text-sm">{`${item?.title || "Reward"
                      }`}</h2>
                  </div>
                </td>
                <td className="">
                  <h2>{`${item?.docket?.shipping_company || "N/A"}`}</h2>
                </td>

                <td className="">
                  <h2 className=" ">{`${item?.points || "N/A"}`}</h2>
                </td>

                <td>
                  <h2
                    className={`${item.order_status === "pending"
                      ? "text-[#FBB360]"
                      : item.order_status === "success"
                        ? "text-[#238551]"
                        : "text-[#CD4246]"
                      }`}
                  >
                    {item.order_status}
                  </h2>
                </td>
              </tr>)
            })}
        </table>
     

        {/* all orders empty  */}
        {data?.data.data.results.length === 0 && (
          <section>
            <div className="bp4-non-ideal-state  h-[70vh]">
              <div className="bp4-non-ideal-state-text">
                <h4 className="bp4-heading" style={{ fontSize: "25px" }}>
                  No orders are here yet
                </h4>
              </div>
            </div>
          </section>
        )}

        {data?.data.data.results.length > 0 && 
        <div className=" text-white flex space-x-2 py-3 items-center  fixed bottom-0 w-[80vw]">
          <button>
            <MdNavigateNext
              onClick={() => {
                handlePageDec();
                scrollTop();
              }}
              className=" text-2xl rotate-180 cursor-pointer"
            />
          </button>

          {pagination.map((item: number, index: number) => {
            return (
              <div
                key={index}
                onClick={() => {
                  setPage(item);
                  scrollTop();
                }}
                className={`${page === item ? "bg-borderColor" : ""
                  } py-1 px-3 bg-cardbg cursor-pointer`}
              >
                <h2>{item}</h2>
              </div>
            );
          })}
          <button>
            <MdNavigateNext
              onClick={() => {
                handlePageInc();
                scrollTop();
              }}
              className=" text-2xl cursor-pointer"
            />
          </button>
        </div>}

      </section>
      <div>
        <Dialog
          isOpen={isOpen}
          onClose={() => {
            setIsOpen(false);
            setResponse("")

          }}
          className="bg-cardbg"
        >
          <div className="text-black text-center py-5">
            {response && (
              <div className="text-lg text-white">
                {
                  response.status === 200 ? "Export orders processed" : (response?.data?.data?.message || response?.message)
                }

                {/* <h2>Session Timed Out</h2> */}
              </div>
            )}
            <button
              onClick={() => {
                setIsOpen(false);
                setResponse("")

              }}
              className="px-4 py-2 text-white mt-5 bg-bgBlack"
            >
              close
            </button>
          </div>
        </Dialog>
      </div>
    </>
  );
};

export default Orders;
