import React from "react";
import { Link } from "react-router-dom";
import SharedLayout from "../components/SharedLayout";
import { routes } from "../packages/utils/routes";

const containerData = [
  {
    name: "MongoDB",
    time: "2hr 30min",
    startTime: "12:00 pm",
  },
  {
    name: "MongoDB",
    time: "2hr 30min",
    startTime: "12:00 pm",
  },
  {
    name: "MongoDB",
    time: "2hr 30min",
    startTime: "12:00 pm",
  },
  {
    name: "MongoDB",
    time: "2hr 30min",
    startTime: "12:00 pm",
  },
];

const SystemMonitorContainer = () => {
  return (
      <section className="px-8 h-[88vh] py-8 overflow-y-scroll  pb-5 scrollbar-thin">
        <header className="grid grid-cols-[40%_10%_15%_20%_15%] xl:grid-cols-12 text-[#ABB3BF] py-3 gap-2 xl:gap-3 pl-3 xl:pl-5 text-base bg-cardbg">
          <div className="xl:col-span-2 ">
            <h2>Container Name</h2>
          </div>
          <div className="xl:col-span-2 text-center ">
            <h2>Time</h2>
          </div>
          <div className="xl:col-span-2 text-center ">
            <h2>Start Time</h2>
          </div>
          <div className="xl:col-span-2  px-3 ">
            <h2></h2>
          </div>
          <div className="xl:col-span-2 ">
            <h2></h2>
          </div>
        </header>

        {/* ********** body ******** */}
        {containerData.map((item, index) => {
          const { name, time, startTime } = item;
          return (
            <div
              key={index}
              className="bg-[#111418] grid grid-cols-[40%_10%_15%_20%_15%] xl:grid-cols-12 text-[#ABB3BF] py-2 gap-2 pl-5 text-sm border-b border-borderColor items-center "
            >
              <div className="xl:col-span-2 flex items-center  space-x-3 ">
                <div className=" w-12  ">
                  <img
                    src="/userimage.png"
                    alt="image"
                    className="h-10 w-12 object-contain"
                  />
                </div>
                <h2 className="text-sm  ">{name}</h2>
              </div>
              <div className="xl:col-span-2 text-center">
                <h2>{time}</h2>
              </div>
              <div className="xl:col-span-2 text-center ">
                <h2>{startTime}</h2>
              </div>
              {/* input  */}
              <div className="xl:col-span-2 ">
                <Link to={routes.singleContainer}>
                  <h2 className=" text-[#2F80ED] hover:underline cursor-pointer  inline">
                    Activity
                  </h2>
                </Link>
              </div>
            </div>
          );
        })}
      </section>
  );
};

export default SystemMonitorContainer;
